<template>
  <div
    v-if="!loading"
    :class="[
      'flex-col items-stretch gap-4 flex-shrink shadow-md border rounded-xl w-full py-4 px-8 bg-white md:w-96 lg:flex',
      showFilters ? 'flex absolute z-50 top-5' : 'hidden',
    ]"
  >
    <div class="flex flex-row items-center justify-between">
      <h3 class="text-xl mt-4">{{ t("filters") }}</h3>
      <XMarkIcon @click="toggleFilters" class="h-6 w-6 lg:hidden" />
    </div>
    <hr class="border-b border-stone-200" />
    <div class="flex flex-row justify-between items-center">
      <h5 class="text-lg">{{ t("price") }}</h5>
      {{ t("price_in_sar", { price: priceRange[0] }) }} -
      {{ t("price_in_sar", { price: priceRange[1] }) }}
    </div>
    <vue-slider
      @drag-end="selectSliderFilter('filters_unitPriceRange', priceRange)"
      class="py-0"
      v-model="priceRange"
      v-bind="priceRangeOptions"
    />
    <br />
    <div class="flex flex-row justify-between items-center">
      <h5 class="text-lg">{{ t("total_km_per_day") }}</h5>
      {{ t("km", { value: kmRange }) }}
    </div>
    <vue-slider
      @drag-end="selectSliderFilter('filters_milePerDay', kmRange)"
      class="py-0"
      v-model="kmRange"
      v-bind="kmRangeOptions"
    />
    <br />
    <div class="flex flex-row justify-between items-center">
      <h5 class="text-lg">{{ t("year") }}</h5>
      {{ yearRange[0] }} - {{ yearRange[1] }}
    </div>
    <vue-slider
      @drag-end="selectSliderFilter('filters_yearRange', yearRange)"
      class="py-0"
      v-model="yearRange"
      v-bind="yearRangeOptions"
    />
    <br />
    <!-- <hr class="border-b border-stone-200" />
    <div class="flex flex-row justify-between items-center">
      <h5 class="text-lg">Delivery</h5>
      <input
        type="checkbox"
        class="h-5 w-5 text-stone-500 accent-ejaro"
        value=""
      />
    </div>
    <div class="flex flex-row justify-between items-center">
      <h5 class="text-lg">Super Host</h5>
      <input
        type="checkbox"
        class="h-5 w-5 text-stone-500 accent-ejaro"
        value=""
      />
    </div>
    <div class="flex flex-row justify-between items-center">
      <h5 class="text-lg">Allow Smoking</h5>
      <input
        type="checkbox"
        class="h-5 w-5 text-stone-500 accent-ejaro"
        value=""
      />
    </div> -->
    <hr class="border-b border-stone-200" />
    <label class="text-lg" for="make">{{ t("make") }}</label>
    <div
      class="flex flex-row relative rounded-md items-center justify-evenly border border-stone-500"
    >
      <select
        id="make"
        name="make"
        @change="selectDropdownFilter('filters_make', $event.target.value)"
        :value="selectedFilters.filters_make"
        class="appearance-none z-10 bg-transparent w-full my-3 px-3 rounded-md cursor-pointer focus:outline-none"
      >
        <option
          v-for="make in filters.makes"
          :key="make.id"
          :selected="isValueSelected('filters_make', make.id)"
          :value="make.id"
        >
          {{ make.title }}
        </option>
      </select>
      <img
        class="w-3 absolute ltr:right-0 ltr:mr-3 rtl:left-0 rtl:ml-3 z-0"
        src="@/assets/icons/chevron-down-black.svg"
        alt="Chevron Down"
      />
    </div>
    <hr class="border-b border-stone-200" />
    <label class="text-lg" for="make">{{ t("model") }}</label>
    <div
      :class="[
        'flex flex-row relative rounded-md px-3 items-center justify-evenly border border-stone-500',
        models.length < 2 ? 'bg-gray-300 cursor-not-allowed' : '',
      ]"
    >
      <select
        id="model"
        name="model"
        :disabled="models.length < 2"
        @change="selectDropdownFilter('filters_model', $event.target.value)"
        :value="selectedFilters.filters_model"
        class="appearance-none z-10 bg-transparent w-full py-3 rounded-md cursor-pointer focus:outline-none disabled:bg-gray-300 disabled:cursor-not-allowed"
      >
        <option
          v-for="model in models"
          :key="model.id"
          :selected="isValueSelected('filters_model', model.id)"
          :value="model.id"
        >
          {{ model.title }}
        </option>
      </select>
      <img
        class="w-3 absolute ltr:right-0 ltr:mr-3 rtl:left-0 rtl:ml-3 z-0"
        src="@/assets/icons/chevron-down-black.svg"
        alt="Chevron Down"
      />
    </div>
    <hr class="border-b border-stone-200" />
    <label class="text-lg" for="seat">{{ t("seats") }}</label>
    <Menu as="div" class="relative w-full">
      <MenuButton
        class="w-full flex flex-row items-center p-3 justify-evenly border border-stone-500 rounded-md cursor-pointer"
      >
        <input
          readonly
          name="seats"
          type="text"
          :value="
            selectedFilters['filters_seats'] &&
            selectedFilters['filters_seats'] !== 0
              ? selectedFilters['filters_seats']
              : 'Any'
          "
          class="rounded-md w-full focus:outline-none"
        />
        <img
          class="w-3"
          src="@/assets/icons/chevron-down-black.svg"
          alt="Chevron Down"
        />
      </MenuButton>
      <transition
        enter-active-class="transition ease-out duration-100"
        enter-from-class="transform opacity-0 scale-95"
        enter-to-class="transform opacity-100 scale-100"
        leave-active-class="transition ease-in duration-75"
        leave-from-class="transform opacity-100 scale-100"
        leave-to-class="transform opacity-0 scale-95"
      >
        <MenuItems
          class="absolute ltr:left-0 rtl:right-0 z-10 mt-2 max-h-52 w-full origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 overflow-scroll focus:outline-none"
        >
          <MenuItem
            v-for="seat in seats"
            class="hover:bg-ejaro hover:text-white"
            :key="seat.value"
            @click="selectDropdownFilter('filters_seats', seat.value)"
          >
            <div
              :class="[
                isValueSelected('filters_seats', seat.value)
                  ? 'bg-gray-200'
                  : 'cursor-pointer',
                'py-2 text-sm text-gray-700 flex flex-row justify-start items-center px-4',
              ]"
            >
              {{ seat.label }}
            </div>
          </MenuItem>
        </MenuItems>
      </transition>
    </Menu>
    <hr class="border-b border-stone-200" />
    <h5 class="text-lg">{{ t("transmission.name") }}</h5>
    <div class="flex flex-row flex-wrap gap-5">
      <span
        :class="[
          'rounded-xl px-3 py-1 border border-ejaro transform shadow-md cursor-pointer',
          {
            'bg-red-600 bg-opacity-20 text-ejaro': isValueSelected(
              'filters_transmission',
              transmission.value
            ),
          },
        ]"
        v-for="transmission in filters.transmissions"
        :key="transmission.value"
        @click="selectFilter('transmission', transmission.value)"
      >
        {{ transmission.label }}
      </span>
    </div>
    <hr class="border-b border-stone-200" />
    <h5 class="text-lg">{{ t("fuel_type.name") }}</h5>
    <div class="flex flex-row flex-wrap gap-5">
      <span
        :class="[
          'flex flex-row gap-2 rounded-xl px-3 py-1 border border-ejaro transform shadow-md cursor-pointer',
          {
            'bg-red-600 bg-opacity-20 text-ejaro': isValueSelected(
              'filters_fuelTypes',
              fuelType.id
            ),
          },
        ]"
        v-for="fuelType in filters.fuelTypes"
        :key="fuelType.value"
        @click="selectFilter('fuelTypes', fuelType.id)"
      >
        {{ fuelType.label }}
      </span>
    </div>
  </div>
  <div
    v-else
    class="flex-col items-center flex-shrink shadow-md border rounded-xl p-4 bg-white w-11/12 hidden md:w-96 lg:flex"
  >
    <span class="mt-4"></span>
    <span class="h-5 w-28 bg-gray-200 animate-pulse rounded-lg"></span>
    <span class="mt-2 h-4 w-24 bg-gray-200 animate-pulse rounded-lg"></span>
    <hr class="border-b border-gray-200 w-full my-4" />
    <div class="flex flex-row justify-start items-center gap-2 w-full">
      <span class="h-4 w-16 bg-gray-200 animate-pulse rounded-lg"></span>
      <span class="h-4 w-16 bg-gray-200 animate-pulse rounded-lg"></span>
    </div>
    <hr class="border-b border-gray-200 w-full my-4" />
    <review-progress-bar :loading="true" />
    <review-progress-bar :loading="true" />
    <review-progress-bar :loading="true" />
    <review-progress-bar :loading="true" />
    <hr class="border-b border-gray-200 w-full my-4" />
    <verified-info :loading="true" />
    <verified-info :loading="true" />
    <verified-info :loading="true" />
    <verified-info :loading="true" />
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import { useI18n } from "@/composables/i18n";
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/antd.css";
import VerifiedInfo from "@/components/VerifiedInfo";
import ReviewProgressBar from "@/components/ReviewProgressBar";
import { onMounted, watch } from "@vue/runtime-core";
import { XMarkIcon } from "@heroicons/vue/24/outline";
import { Menu, MenuItem, MenuItems, MenuButton } from "@headlessui/vue";
import { useRoute, useRouter } from "vue-router";
import { getModels } from "@/service/car";

export default {
  name: "FilterCard",
  components: {
    VueSlider,
    XMarkIcon,
    VerifiedInfo,
    ReviewProgressBar,
    Menu,
    MenuItem,
    MenuItems,
    MenuButton,
  },
  props: {
    loading: {
      type: Boolean,
      required: false,
    },
    filters: {
      type: Object,
      required: true,
    },
    showFilters: {
      type: Boolean,
      required: true,
    },
    toggleFilters: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const { t, currentLocale } = useI18n();

    const router = useRouter();
    const route = useRoute();

    const selectedFilters = ref({
      filters_unitPriceRange_min:
        route.query["filters_unitPriceRange_min"] || 0,
      filters_unitPriceRange_max:
        route.query["filters_unitPriceRange_max"] || 0,
      filters_yearRange_min: route.query["filters_yearRange_min"] || 0,
      filters_yearRange_max: route.query["filters_yearRange_max"] || 0,
      filters_seats: parseInt(route.query["filters_seats"]) || 0,
      filters_make: parseInt(route.query["filters_make"]) || 0,
      filters_model: parseInt(route.query["filters_model"]) || 0,
      filters_deliveryStatus: route.query["filters_deliveryStatus"] || 0,
      filters_milePerDay: route.query["filters_milePerDay"] || 0,
      filters_transmission:
        route.query["filters_transmission"] instanceof Array
          ? route.query["filters_transmission"]
          : (route.query["filters_transmission"] && [
              route.query["filters_transmission"],
            ]) ||
            [],
      filters_fuelTypes:
        route.query["filters_fuelTypes"] instanceof Array
          ? route.query["filters_fuelTypes"]
          : (route.query["filters_fuelTypes"] && [
              route.query["filters_fuelTypes"],
            ]) ||
            [],
    });

    const seats = ref([{ label: "Any", value: 0 }]);
    const models = ref([{ title: "All Models", id: 0 }]);

    const priceRange = ref([
      parseInt(route.query["filters_unitPriceRange_min"]) || 50,
      parseInt(route.query["filters_unitPriceRange_max"]) || 3000,
    ]);
    const priceRangeOptions = ref({
      dotSize: 20,
      marks: [50, 3000],
      width: "auto",
      height: 7,
      clickable: false,
      contained: true,
      direction: currentLocale() === "ar" ? "rtl" : "ltr",
      data: null,
      dataLabel: "label",
      dataValue: "value",
      min: 50,
      max: 3000,
      interval: 50,
      tooltip: "active",
      tooltipPlacement: "top",
      tooltipFormatter: (v) => {
        return v + " SAR";
      },
      process: true,
      dotStyle: {
        backgroundColor: "#AB0310",
        borderColor: "#AB0310",
      },
      dotAttrs: {},
      processStyle: {
        backgroundColor: "#AB0310",
      },
    });

    const kmRange = ref(parseInt(route.query["filters_milePerDay"]) || 50);
    const kmRangeOptions = ref({
      dotSize: 20,
      marks: [50, 5000],
      width: "auto",
      height: 7,
      contained: true,
      direction: currentLocale() === "ar" ? "rtl" : "ltr",
      data: null,
      dataLabel: "label",
      dataValue: "value",
      min: 50,
      max: 5000,
      interval: 50,
      tooltip: "active",
      tooltipPlacement: "top",
      tooltipFormatter: (v) => {
        return v + " KM";
      },
      process: true,
      dotStyle: {
        backgroundColor: "#AB0310",
        borderColor: "#AB0310",
      },
      processStyle: {
        backgroundColor: "#AB0310",
      },
    });

    const yearRange = ref([]);
    const yearRangeOptions = ref({
      dotSize: 20,
      marks: [0, 1],
      width: "auto",
      height: 7,
      contained: true,
      direction: currentLocale() === "ar" ? "rtl" : "ltr",
      data: null,
      dataLabel: "label",
      dataValue: "value",
      min: 0,
      max: 1,
      interval: 1,
      tooltip: "active",
      tooltipPlacement: "top",
      // tooltipFormatter: (v) => {
      //   return v;
      // },
      process: true,
      dotStyle: {
        backgroundColor: "#AB0310",
        borderColor: "#AB0310",
      },
      processStyle: {
        backgroundColor: "#AB0310",
      },
    });

    const updateQuery = () => {
      router.replace({
        //NICEE used to add to route query
        query: {
          ...route.query,
          ...selectedFilters.value,
        },
      });
    };

    const selectMake = (make) => {
      selectedMake.value = make.title;
      selectedFilters.value["filters_make"] = make.id;
      updateQuery();
    };

    const selectSliderFilter = (filter, value) => {
      if (filter + "_min" in selectedFilters.value) {
        selectedFilters.value[filter + "_min"] = value[0];
        selectedFilters.value[filter + "_max"] = value[1];
      } else {
        selectedFilters.value[filter] = value;
      }
      updateQuery();
    };

    const selectDropdownFilter = async (filter, value) => {
      selectedFilters.value[filter] = value;
      if (filter === "filters_make") {
        const response = await getModels(value);
        models.value = [{ title: "All Models", id: 0 }];
        selectedFilters.value["filters_model"] = 0;
        if (response.models) {
          models.value.push(...response.models);
        }
      }
      updateQuery();
    };

    const selectFilter = (type, value) => {
      if (selectedFilters.value["filters_" + type].includes(value)) {
        selectedFilters.value["filters_" + type] = selectedFilters.value[
          "filters_" + type
        ].filter((v) => v !== value);
      } else {
        selectedFilters.value["filters_" + type].push(value);
      }

      updateQuery();
    };

    const isValueSelected = (type, value) => {
      if (!(selectedFilters.value[type] instanceof Array)) {
        return selectedFilters.value[type] === value;
      } else {
        return selectedFilters.value[type].includes(value);
      }
    };

    watch(
      () => props.filters,
      (newVal) => {
        let newYearRange = [
          newVal.years[newVal.years?.length - 1].title,
          newVal.years[0].title,
        ];

        yearRange.value = [
          parseInt(route.query["filters_yearRange_min"]) || newYearRange[0],
          parseInt(route.query["filters_yearRange_max"]) || newYearRange[1],
        ];
        yearRangeOptions.value.marks = newYearRange;
        yearRangeOptions.value.min = newYearRange[0];
        yearRangeOptions.value.max = newYearRange[1];
        seats.value.push(...newVal.seats);
      },
      { deep: true }
    );

    onMounted(async () => {
      if (selectedFilters.value["filters_make"]) {
        const response = await getModels(selectedFilters.value["filters_make"]);
        models.value = [{ title: "All Models", id: 0 }];
        if (response.models) {
          models.value.push(...response.models);
        }
      }
    });

    return {
      t,
      seats,
      models,
      kmRange,
      yearRange,
      selectMake,
      priceRange,
      selectFilter,
      // selectedMake,
      kmRangeOptions,
      isValueSelected,
      selectedFilters,
      yearRangeOptions,
      priceRangeOptions,
      selectSliderFilter,
      selectDropdownFilter,
    };
  },
};
</script>
