import VCalendar from "v-calendar";
import store from "@/store/index.js";
import router from "@/router/router.js";
import Carousel3d from "vue3-carousel-3d";
import vueClickAway from "vue3-click-away";

import "../packs/i18n/en";
import "../packs/i18n/ar";
import "../css/application.css";
import "v-calendar/dist/style.css";
import "vue3-carousel-3d/dist/index.css";

import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

import { createApp } from "vue";
import App from "@/Pages/App.vue";

const isProductionLikeEnvironment = () => {
  process.env.RAILS_ENV == "production" || process.env.RAILS_ENV == "staging";
}

export default () => {
  document.addEventListener("DOMContentLoaded", () => {
    const app = createApp(App);

    if (process.env.VUE_APP_SENTRY_ENABLED || isProductionLikeEnvironment()) {
      Sentry.init({
        app,
        dsn: "https://e0d11f180e5c4d5fadeb40a58558c8a6@o1208968.ingest.sentry.io/4504196195090432",
        integrations: [
          new BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            tracePropagationTargets: ["ejaro.com", "dev.ejaro.com", /^\//],
          }),
        ],
        environment: process.env.RAILS_ENV,
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
      });

      if (process.env.RAILS_ENV !== "production") {
        Sentry.attachErrorHandler(app, { logErrors: true });
      }
    }

    app.use(store);
    app.use(router);
    app.use(Carousel3d);
    app.use(vueClickAway);
    app.use(VCalendar, {});
    app.mount("#app");
  });
};
