<template>
  <div
    v-if="!loading"
    class="flex flex-row items-center justify-between w-full pb-2"
    :class="textSize || 'text-sm'"
  >
    <p>{{ name }}</p>
    <div class="flex flex-row items-center justify-between w-3/5 gap-4">
      <div class="h-1 w-full bg-stone-200">
        <hr
          class="h-1 bg-ejaro"
          :style="
            value ? `width: ${(Math.ceil(value) / 5) * 100}%;` : 'width: 0;'
          "
        />
      </div>
      <p>
        {{ parseFloat(value || 0).toFixed(1) }}
      </p>
    </div>
  </div>
  <div
    v-else
    class="flex flex-row items-center justify-between w-full pb-2 text-sm"
  >
    <span class="w-20 h-4 animate-pulse bg-gray-200 rounded-lg"></span>
    <div class="flex flex-row items-center justify-between w-3/5 gap-4">
      <div class="h-1 w-full bg-gray-200" :class="!value && 'animate-pulse'">
        <hr class="h-1 w-0 bg-ejaro" />
      </div>
      <p class="h-4 w-6 bg-gray-200 animate-pulse rounded-full"></p>
    </div>
  </div>
</template>

<script>
export default {
  name: "ReviewProgressBar",
  props: ["name", "value", "loading", "textSize"],
};
</script>
