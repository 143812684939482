<template>
  <div
    v-click-away="
      (e) => isSearchDropdownOpen && isClose(e) && toggleSearchDropdown()
    "
    :class="[
      'absolute z-50 pt-4 w-full dropdown',
      !isSearchDropdownOpen && 'sr-only',
      rows > 1 ? 'top-8' : 'top-full',
    ]"
  >
    <div
      class="flex flex-col overflow-y-visible rounded-3xl shadow-lg bg-white p-6"
    >
      <v-date-picker
        dir="ltr"
        is-range
        is-expanded
        :columns="cols"
        :rows="rows"
        :max-date="maxDate"
        :min-date="new Date()"
        :attributes="attributes"
        :locale="currentLocale()"
        v-model="range"
        color="red"
        mode="date"
        class="border-0"
      ></v-date-picker>
      <br />
      <div class="flex flex-row gap-x-2">
        <div
          :class="[
            'flex flex-col justify-around gap-4 pt-5',
            currentLocale() === 'ar' && 'text-right',
          ]"
        >
          <p>{{ t("start_time") }}:</p>
          <p>{{ t("end_time") }}:</p>
        </div>
        <v-date-picker
          dir="ltr"
          is-range
          :max-date="maxDate"
          :min-date="new Date()"
          :valid-hours="validHours"
          :locale="currentLocale()"
          v-model="range"
          color="red"
          mode="time"
          class="border-0"
        >
        </v-date-picker>
      </div>
      <!-- <div class="flex flex-row justify-end">
        <button
          @click="toggleSearchDropdown"
          type="button"
          class="bg-ejaro w-min px-10 py-2 rounded-lg text-white"
        >
          {{ t("save") }}
        </button>
      </div> -->
    </div>
  </div>
</template>

<script>
import { useI18n } from "@/composables/i18n";
import { mapState, mapMutations } from "vuex";
import { addMonths, parseISO } from "date-fns";

export default {
  name: "SearchDropDown",
  props: {
    rows: {
      type: Number,
      default: 1,
    },
    cols: {
      type: Number,
      default: 2,
    },
  },
  data() {
    return {
      maxDate: addMonths(new Date(), 6),
      attributes: [
        {
          key: "today",
          highlight: "blue",
          dates: new Date(),
        },
      ],
    };
  },
  computed: {
    ...mapState(["isSearchDropdownOpen"]),
    range: {
      get() {
        return {
          start: parseISO(this.$store.state.startDateTime),
          end: parseISO(this.$store.state.endDateTime),
        };
      },
      set(value) {
        this.$store.commit("setStartDateTime", value.start);
        this.$store.commit("setEndDateTime", value.end);
      },
    },
  },
  methods: {
    ...mapMutations(["toggleSearchDropdown"]),
    ...useI18n(),
    isClose(e) {
      return !e.target.closest(".dropdown");
    },
    validHours(hour, { date }) {
      if (date.getDate() === new Date().getDate()) {
        return hour - 1 > new Date().getHours();
      }
      return true;
    },
  },
};
</script>
