<template>
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" @close="closeModal" class="relative z-10">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 transform bg-black opacity-25" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div
          class="flex min-h-full items-center justify-center p-4 text-center"
        >
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              class="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all"
            >
              <DialogTitle
                as="h3"
                class="text-lg font-medium text-center leading-6 text-gray-900"
              >
                {{ modalTitle }}
                <!-- /Payment successful -->
              </DialogTitle>
              <div class="mt-2">
                <p class="text-sm text-gray-500 text-center">
                  {{ modalMessage }}
                  <!-- Your payment has been successfully submitted. We’ve sent you
                  an email with all of the details of your order. -->
                </p>
              </div>

              <div class="mt-4 flex items-center justify-center">
                <button
                  type="button"
                  class="inline-flex justify-center rounded-md border border-transparent bg-ejaro px-4 py-2 text-sm font-medium text-white focus:outline-none"
                  @click="closeModal"
                >
                  {{ modalButtonText }}
                  <!-- Got it, thanks! -->
                </button>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogTitle,
} from "@headlessui/vue";

export default {
  name: "ModalDialog",
  components: {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogPanel,
    DialogTitle,
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    closeModal: {
      type: Function,
      required: true,
    },
    modalTitle: {
      type: String,
      required: true,
    },
    modalMessage: {
      type: String,
      required: true,
    },
    modalButtonText: {
      type: String,
      required: true,
    },
  },
  setup() {
    // const isOpen = ref(true);

    // function closeModal() {
    //   isOpen.value = false;
    // }
    // function openModal() {
    //   isOpen.value = true;
    // }

    return {
      //   isOpen,
      //   closeModal,
      //   openModal,
    };
  },
};
</script>
