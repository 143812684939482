<template>
  <div>
    <div class="relative hidden lg:flex">
      <img
        class="object-cover w-full min-h-[500px] max-h-[65vh]"
        src="@/assets/images/background.jpg"
        alt="Background"
        style="filter: brightness(0.75)"
      />
      <SearchBar v-if="isShowSearchBar" />
      <div class="absolute top-1/3 px-5 max-w-2xl md:top-2/3 rtl:text-right">
        <h1 class="text-white pb-5 md:text-3xl">
          {{ t("slogan") }}
        </h1>
        <img
          class="w-1/3 md:w-auto"
          src="@/assets/images/general-transport-authority.png"
          alt="General Transport Authority"
        />
      </div>
    </div>
    <!-- Mobile Search Bar -->
    <SearchBar
      class="py-5 lg:hidden bg-ejaro"
      v-if="isShowSearchBar"
      searchBtnColor="ejaro"
      :isAbsolute="false"
    />
    <NearbyCarsBar :nearbyCars="carsNearby" :cars="cars" :loading="loading" />
    <WelcomeBar />
    <BenefitsBar />
    <CitiesBar :cities="cities" />
    <br />
    <br />
    <div class="relative h-96 lg:h-auto mb-12">
      <img
        class="object-cover h-full w-full"
        src="@/assets/images/background-1.jpg"
        alt="Moving Cars By Hand"
      />
      <div
        class="absolute inset-0 bg-gradient-to-r rtl:bg-gradient-to-l from-stone-900 via-transparent"
      ></div>
      <div
        class="absolute max-w-md top-8 md:top-1/4 left-12 rtl:right-12 rtl:text-right text-white"
      >
        <p class="text-2xl md:text-3xl">
          {{ t("earn_extra.title") }}
        </p>
        <br />
        <p class="text-lg">
          {{ t("earn_extra.description") }}
        </p>
        <br />
        <br />
        <button
          id="earn-extra-button"
          class="inline-flex items-center rounded-md bg-ejaro px-3 py-2 text-sm text-white shadow-sm hover:no-underline"
          @click="
            $store.state.user ? redirectToDownload() : $router.push('/login')
          "
        >
          {{ t("share_your_vehicle") }}
        </button>
      </div>
    </div>
    <FeaturedBar />
    <br />
    <br />
    <DownloadAppBar />
    <br />
    <PaymentMethodsBar />
  </div>
</template>

<script>
import { useStore } from "vuex";
import { nearbyCars } from "@/service/car";
import { ref, onMounted, watch } from "vue";
import { useI18n } from "@/composables/i18n";
import { redirectToDownload } from "@/common";
import SearchBar from "@/components/SearchBar.vue";
import CitiesBar from "@/components/CitiesBar.vue";
import WelcomeBar from "@/components/WelcomeBar.vue";
import BenefitsBar from "@/components/BenefitsBar.vue";
import FeaturedBar from "@/components/FeaturedBar.vue";
import NearbyCarsBar from "@/components/NearbyCarsBar.vue";
import DownloadAppBar from "@/components/DownloadAppBar.vue";
import PaymentMethodsBar from "@/components/PaymentMethodsBar.vue";

export default {
  name: "Home",
  components: {
    SearchBar,
    BenefitsBar,
    CitiesBar,
    FeaturedBar,
    DownloadAppBar,
    PaymentMethodsBar,
    NearbyCarsBar,
    WelcomeBar,
  },
  setup() {
    const cars = ref([]); //? This is for the old design, is used to show cars that might be interesting for the user if no cars are found in the nearby cars
    const cities = ref([]);
    const { t } = useI18n();
    const carsNearby = ref([]);
    const loading = ref(false);
    const isShowSearchBar = ref(true);

    const store = useStore();

    onMounted(() => {
      document.addEventListener("scroll", () => {
        if (window.screen.availWidth > 768) {
          if (window.scrollY > window.screen.availHeight * 0.25) {
            isShowSearchBar.value = false;
          } else {
            isShowSearchBar.value = true;
          }
        }
      });
    });

    watch(
      () => [store.state.searchQuery.lat, store.state.searchQuery.lng],
      () => {
        loading.value = true;
        nearbyCars(
          store.state.user,
          store.state.searchQuery.lat,
          store.state.searchQuery.lng
        ).then((response) => {
          cities.value = response.result?.cities;
          carsNearby.value = response.result?.nearAround;
          cars.value = response.result?.cars; // TODO remove this when new design is implemented
          loading.value = false;
        });
      },
      { immediate: true }
    );

    return {
      t,
      cars,
      cities,
      loading,
      carsNearby,
      isShowSearchBar,
      redirectToDownload,
    };
  },
};
</script>
