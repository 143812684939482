<template>
  <div>
    <div v-if="loading" class="flex flex-row items-center justify-center my-5">
      <span class="animate-pulse h-7 w-52 rounded-lg bg-gray-200"></span>
    </div>
    <p
      v-else-if="!loading && (nearbyCars.length || cars.length)"
      class="text-center text-2xl font-bold my-5"
    >
      {{
        nearbyCars.length ? t("vehicles_nearby") : t("vehicles_you_might_like")
      }}
    </p>
    <div
      id="nearby-cars"
      class="px-4 flex flex-row overflow-x-scroll scrollbar-hide gap-2 md:gap-5 mb-5"
    >
      <CarCard
        :cars="nearbyCars.length ? nearbyCars : cars"
        :loading="loading"
        :loadingCardsCount="6"
      />
    </div>
    <!-- <div
      v-if="!loading && (nearbyCars.length || cars.length)"
      @click="showMore = !showMore"
      class="cursor-pointer"
    >
      <p class="text-center text-lg text-black">
        {{ showMore ? t("show_less") : t("show_more") }}
      </p>
      <span class="flex items-center mb-6 mt-2 justify-center">
        <img
          :class="['w-7', showMore ? 'transform rotate-180' : 'animate-bounce']"
          src="@/assets/icons/double-down-arrow.svg"
          alt=""
        />
      </span>
    </div> -->
  </div>
</template>

<script>
import { onMounted, ref, watch } from "vue";
import { useI18n } from "@/composables/i18n";
import CarCard from "@/components/CarCard.vue";

export default {
  name: "NearbyCarsBar",
  components: {
    CarCard,
  },
  props: ["nearbyCars", "cars", "loading"],
  setup() {
    const { t } = useI18n();
    const showMore = ref(false);

    onMounted(() => {
      const scrollContainer = document.getElementById("nearby-cars");

      scrollContainer.addEventListener("wheel", (evt) => {
        let isTouchPad = evt.wheelDeltaY
          ? evt.wheelDeltaY === -3 * evt.deltaY
          : evt.deltaMode === 0;

        if (!isTouchPad) {
          evt.preventDefault();
          if (localStorage.getItem("lang") === "ar") {
            scrollContainer.scrollLeft -= evt.deltaY;
          } else {
            scrollContainer.scrollLeft += evt.deltaY;
          }
        }
      });
    });
    return {
      t,
      showMore,
    };
  },
};
</script>
