import numeral from "numeral";

export const useNumerals = () => {
  function formatFloat(number, formatString = "0,0.00") {
    return numeral(number).format(formatString);
  }

  return {
    formatFloat,
  };
};
