<template>
  <div v-if="!loading">
    <p class="rtl:text-right">{{ t("reviews.text") }}</p>
    <br />
    <div v-if="review.userReviews.length">
      <div
        v-for="userReview in review.userReviews"
        :key="userReview.id"
        class="flex flex-col items-start pb-4"
      >
        <div class="flex flex-row justify-start items-center my-2 gap-5">
          <img
            class="h-12 w-12 rounded-full object-cover border border-ejaro"
            :src="userReview.image || dummyUser"
            alt="Reviewer Picture"
          />
          <div class="rtl:text-right">
            <p class="text-xs">{{ userReview.fname }} {{ userReview.lname }}</p>
            <p class="text-xs text-stone-400">{{ userReview.reviewed_at }}</p>
            <div class="flex flex-row">
              <img
                v-for="i in Math.floor(userReview.main_rating)"
                :key="[userReview.fname, i].join(' ')"
                src="@/assets/icons/star.svg"
                alt=""
              />
              <img
                v-if="userReview.main_rating % 1 !== 0"
                src="@/assets/icons/star-half.svg"
                alt=""
              />
              <img
                v-for="i in 5 - Math.ceil(userReview.main_rating)"
                :key="i"
                src="@/assets/icons/star-empty.svg"
                alt=""
              />
            </div>
          </div>
        </div>
        <p class="max-w-3xl">
          {{ userReview.review }}
        </p>
      </div>
    </div>
    <div v-else class="flex items-center justify-center text-stone-400">
      <p>{{ t("no_reviews") }}</p>
    </div>
  </div>
  <div v-else>
    <div class="h-4 w-16 rounded-lg bg-gray-200 animate-pulse"></div>
    <br />
    <div v-for="i in 3" :key="i" class="flex flex-col items-start pb-4">
      <div class="flex flex-row justify-start items-center my-2 gap-5">
        <div
          class="h-12 w-12 rounded-full border bg-gray-200 animate-pulse"
        ></div>
        <div class="rtl:text-right">
          <div class="h-3 w-32 rounded-lg bg-gray-200 animate-pulse"></div>
          <div class="mt-1 h-3 w-28 rounded-lg bg-gray-200 animate-pulse"></div>
          <div class="flex flex-row mt-1">
            <img
              v-for="i in 5"
              :key="i"
              src="@/assets/icons/star-empty.svg"
              class="animate-pulse"
              alt=""
            />
          </div>
        </div>
      </div>
      <span class="h-4 w-full rounded-lg bg-gray-200 animate-pulse"></span>
      <span class="mt-2 h-4 w-1/2 rounded-lg bg-gray-200 animate-pulse"></span>
    </div>
  </div>
</template>

<script>
import { useI18n } from "@/composables/i18n";
import dummyUser from "@/assets/icons/user-anonymous.svg";

export default {
  name: "ReviewCard",
  props: ["review", "loading"],
  setup() {
    const { t } = useI18n();

    return {
      t,
      dummyUser,
    };
  },
};
</script>
