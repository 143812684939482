import axios from "axios";
import jstz from "jstz";

const timezone = jstz.determine();

axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.common["timeZone"] = timezone.name();
axios.defaults.headers.common["deviceType"] = "web";

export const getHeaders = () => {
  return {
    language: localStorage.getItem("lang"),
    accessToken:
      sessionStorage.getItem("token") || localStorage.getItem("token") || null,
  };
};
