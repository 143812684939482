<template>
  <div
    class="bg-black w-full grid grid-flow-row items-center justify-items-center p-5 xl:grid-cols-2"
  >
    <iframe
      class="w-full h-96 pb-5 md:pb-0"
      src="https://www.youtube.com/embed/TWKC3LIw4dE?autoplay=0&rel=0&showinfo=0&loop=1"
      title="Welcome to Ejaro - مرحباً بكم في إيجارو"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>
    <div class="text-white max-w-xl text-center md:text-left rtl:md:text-right">
      <p class="text-4xl font-bold">{{ t("welcome_to_ejaro") }}</p>
      <p class="text-2xl">{{ t("trusted_sharing") }}</p>
      <br />
      <p class="text-justify">
        {{ t("welcome_to_ejaro_description") }}
      </p>
    </div>
  </div>
</template>

<script>
import { useI18n } from "@/composables/i18n";
export default {
  name: "WelcomeBar",
  setup() {
    const { t } = useI18n();
    return {
      t,
    };
  },
};
</script>
