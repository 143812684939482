<template>
  <div v-if="!loading">
    <div class="flex flex-col">
      <div class="flex flex-row gap-5">
        <p class="text-xl text-start">
          {{ car.make.title }} {{ car.model.title }} {{ car.year.title }}
        </p>
        <p class="flex items-center gap-2 bg-ejaro rounded-md text-white px-2">
          {{ formatFloat(car.avgCarRating) }}
          <img src="../assets/icons/star-white.svg" alt="" />
        </p>
      </div>
      <p class="text-start pt-2">
        {{ t("rentals", { count: car.totalTrips }) }} |
        {{ t("km", { value: car.distance || "0.0" }) }}
      </p>
    </div>
    <br />
    <hr />
    <car-info-bar
      :title="t('trip_dates')"
      :actionTitle="t('edit')"
      :actionCallback="
        () => {
          toggleSearchDropdown;
        }
      "
      :leadingIcon="dateIcon"
    >
      <template v-slot:subtitle>
        <p>{{ formatDate(startDateTime) }}</p>
        <img
          class="w-5 transform rtl:rotate-180"
          src="@/assets/icons/white-chevron-right.svg"
          alt="Chevron Right"
        />
        <p>{{ formatDate(endDateTime) }}</p>
      </template>
    </car-info-bar>
    <car-info-bar
      :title="t('pickup_return_location')"
      :subtitle="
        car.pickupLocation.city +
        ', ' +
        car.pickupLocation.state +
        ' ' +
        car.pickupLocation.zip
      "
      :leadingIcon="locationIcon"
    />
    <car-info-bar
      :title="t('insurance_provider')"
      :subtitle="car.carInsurances || t('no_insurance')"
      :leadingIcon="insuranceIcon"
    />
    <car-info-bar
      :title="t('km_included.name')"
      :subtitle="
        t('km_included.extra', {
          km: parseInt(car.maxDayMiles),
          extra: parseFloat(car.pricePerExtraMile).toFixed(2),
        })
      "
      :leadingIcon="odometerIcon"
    />
    <div class="flex flex-col gap-2">
      <div class="flex flex-row justify-around items-center py-5">
        <div class="flex flex-col gap-2 items-center justify-center">
          <img
            class="h-10"
            src="@/assets/icons/seat.svg"
            alt="Date Time Icon"
          />
          <p>{{ t("seats_count", { count: car.seats }) }}</p>
        </div>
        <div class="flex flex-col gap-2 items-center justify-center">
          <img
            class="h-10"
            src="@/assets/icons/door.svg"
            alt="Date Time Icon"
          />
          <p>{{ t("doors_count", { count: car.doors }) }}</p>
        </div>
        <div class="flex flex-col gap-2 items-center justify-center">
          <img
            class="h-10"
            src="@/assets/icons/transmission.svg"
            alt="Date Time Icon"
          />
          <p>{{ t("transmission." + car.transmission.toLowerCase()) }}</p>
        </div>
        <div class="flex flex-col gap-2 items-center justify-center">
          <img
            class="h-10"
            src="@/assets/icons/petrol.svg"
            alt="Date Time Icon"
          />
          <p>
            {{
              t(
                "fuel_type." +
                  car.withFuelType.split(" ").join("_").toLowerCase()
              )
            }}
          </p>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <div class="flex flex-col">
      <div class="flex flex-row gap-5 h-6">
        <p
          class="text-xl text-start bg-gray-200 animate-pulse w-48 rounded-md"
        ></p>
        <p
          class="flex items-center gap-2 bg-gray-200 animate-pulse w-16 rounded-md text-white px-2"
        ></p>
      </div>
      <p
        class="text-start h-5 bg-gray-200 animate-pulse w-32 mt-2 rounded-md"
      ></p>
    </div>
    <br />
    <hr />
    <div class="flex flex-col gap-3 py-4">
      <div class="h-6 w-48 bg-gray-200 animate-pulse rounded-md"></div>
      <div class="h-6 w-56 bg-gray-200 animate-pulse rounded-md"></div>
    </div>
    <hr />
    <div class="flex flex-col gap-3 py-4">
      <div class="h-6 w-48 bg-gray-200 animate-pulse rounded-md"></div>
      <div class="h-6 w-56 bg-gray-200 animate-pulse rounded-md"></div>
    </div>
    <hr />
    <div class="flex flex-col gap-3 py-4">
      <div class="h-6 w-48 bg-gray-200 animate-pulse rounded-md"></div>
      <div class="h-6 w-56 bg-gray-200 animate-pulse rounded-md"></div>
    </div>
    <hr />
    <div class="flex flex-col gap-3 py-4">
      <div class="h-6 w-48 bg-gray-200 animate-pulse rounded-md"></div>
      <div class="h-6 w-56 bg-gray-200 animate-pulse rounded-md"></div>
    </div>
    <hr />
    <div class="flex flex-col gap-2">
      <div class="flex flex-row justify-around items-center py-5">
        <div class="flex flex-col gap-2 items-center justify-center">
          <div class="h-10 w-10 rounded-lg bg-gray-200 animate-pulse"></div>
          <p class="h-4 w-16 bg-gray-200 animate-pulse rounded-md"></p>
        </div>
        <div class="flex flex-col gap-2 items-center justify-center">
          <div class="h-10 w-10 rounded-lg bg-gray-200 animate-pulse"></div>
          <p class="h-4 w-16 bg-gray-200 animate-pulse rounded-md"></p>
        </div>
        <div class="flex flex-col gap-2 items-center justify-center">
          <div class="h-10 w-10 rounded-lg bg-gray-200 animate-pulse"></div>
          <p class="h-4 w-16 bg-gray-200 animate-pulse rounded-md"></p>
        </div>
        <div class="flex flex-col gap-2 items-center justify-center">
          <div class="h-10 w-10 rounded-lg bg-gray-200 animate-pulse"></div>
          <p class="h-4 w-16 bg-gray-200 animate-pulse rounded-md"></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import { useDate } from "@/composables/date";
import { useI18n } from "@/composables/i18n";
import CarInfoBar from "../components/CarInfoBar.vue";
import ReviewCard from "../components/ReviewCard.vue";
import ReviewProgressBar from "@/components/ReviewProgressBar.vue";

import dateIcon from "@/assets/icons/date-time.svg";
import locationIcon from "@/assets/icons/location.svg";
import odometerIcon from "@/assets/icons/odometer.svg";
import insuranceIcon from "@/assets/icons/insurance.svg";
import descriptionIcon from "@/assets/icons/clipboard.svg";

export default {
  name: "CarDetailsBar",
  props: ["car", "loading"],
  components: { CarInfoBar, ReviewCard, ReviewProgressBar },
  computed: {
    ...mapState(["startDateTime", "endDateTime"]),
    ...mapMutations(["toggleSearchDropdown"]),
  },

  setup() {
    const { t } = useI18n();
    const { formatDate } = useDate();

    const formatFloat = (value, decimal = "1") => {
      return parseFloat(value).toFixed(decimal);
    };

    return {
      t,
      dateIcon,
      formatDate,
      formatFloat,
      locationIcon,
      odometerIcon,
      insuranceIcon,
      descriptionIcon,
    };
  },
};
</script>
