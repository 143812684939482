<template>
  <template v-if="!loading">
    <div
      v-for="car in cars"
      :key="car.id"
      class="flex flex-col overflow-hidden rounded-xl shadow border min-w-[22rem] max-w-sm"
    >
      <router-link
        :to="{ name: 'car', params: { car_id: car.id } }"
        class="block no-underline"
      >
        <div class="flex-shrink-0 relative">
          <img
            class="h-48 w-full object-cover"
            :src="car.image"
            alt="Car Image"
          />
          <div
            class="absolute inset-0 bg-gradient-to-b from-stone-900 via-transparent"
          ></div>
          <span
            class="absolute top-0 left-0 bg-ejaro text-white rounded-xl py-2 px-3 font-bold text-sm ml-2 mt-2"
          >
            {{ car.starTag || car.user.starTag }}
          </span>
          <!-- <span
            class="absolute top-0 right-0 rounded-full font-bold text-sm mr-2 mt-2"
          >
            <img
              class="w-12"
              src="@/assets/icons/favorite.svg"
              alt="Favorite"
            />
          </span> -->
        </div>
        <div class="flex flex-1 flex-col justify-between bg-white p-6">
          <div class="flex-1">
            <p
              class="text-base mb-3 font-semibold rtl:text-right text-gray-900"
            >
              {{
                car.make.title + " " + car.model.title + " " + car.year.title
              }}
            </p>
            <div class="text-sm font-bold flex flex-row items-start text-ejaro">
              <img src="@/assets/icons/star.svg" alt="star" /> &nbsp;
              <span class="mr-3">{{
                formatFloat(car.avgCarRating, "0.0")
              }}</span>
              <div
                v-if="car.guestLocationEnabled"
                class="flex flex-row items-center"
              >
                <img
                  class="mx-2 h-4"
                  src="@/assets/icons/car-delivery.svg"
                  alt="car-delivery"
                />
                <p class="font-normal text-black">
                  {{
                    car.guestLocationDeliveryPrice > 0
                      ? t("delivery_available")
                      : t("free_delivery")
                  }}
                </p>
              </div>
              <div v-else class="flex flex-row items-center">
                <img
                  class="mx-2 h-4"
                  src="@/assets/icons/car-pickup.svg"
                  alt="car-pickup"
                />
                <p class="font-normal text-black">{{ t("pickup_only") }}</p>
              </div>
            </div>
          </div>
          <br />
          <hr />
          <div class="mt-6 flex items-center text-base rtl:text-right">
            <div class="flex-grow">
              <span class="text-black font-black">
                {{
                  t("price_in_sar", {
                    price: car.variablePrice.toLocaleString("en"),
                  })
                }}
                /
                {{ t("day") }}
              </span>
            </div>
            <div class="ml-3">
              <p class="text-sm font-light text-gray-900">
                <span class="font-bold">{{ t("weekly_price") }}: &nbsp;</span>
                {{
                  t("price_in_sar", {
                    price: car.week_booking_price.toLocaleString("en"),
                  })
                }}
              </p>
              <p class="text-sm font-light text-gray-900">
                <span class="font-bold">{{ t("monthly_price") }}: &nbsp;</span>
                {{
                  t("price_in_sar", {
                    price: car.month_booking_price.toLocaleString("en"),
                  })
                }}
              </p>
            </div>
          </div>
        </div>
      </router-link>
    </div>
  </template>
  <template v-else>
    <div
      v-for="i in loadingCardsCount"
      :key="i"
      class="flex flex-col overflow-hidden rounded-xl shadow border min-w-[22rem] max-w-sm"
    >
      <div class="flex-shrink-0 relative">
        <div class="flex items-center justify-center bg-gray-100">
          <img
            class="h-48 w-1/2 object-contain animate-pulse"
            color="black"
            src="@/assets/logo-gray.svg"
            alt="Car Image"
          />
        </div>
      </div>
      <div class="flex flex-1 flex-col justify-between bg-white p-6">
        <div class="flex-1">
          <div
            class="animate-pulse mb-3 w-full h-6 rounded-lg bg-gray-200"
          ></div>
          <div
            class="animate-pulse mb-3 w-1/2 h-6 rounded-lg bg-gray-200"
          ></div>
        </div>
        <br />
        <hr />
        <div class="mt-6 flex items-center">
          <div class="flex-grow">
            <div
              class="animate-pulse mb-3 w-1/2 h-6 rounded-lg bg-gray-200"
            ></div>
          </div>
          <div class="ml-3 flex flex-col w-1/2">
            <div
              class="animate-pulse mb-3 w-full h-6 rounded-lg bg-gray-200"
            ></div>
            <div
              class="animate-pulse mb-3 w-full h-6 rounded-lg bg-gray-200"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </template>
</template>

<script>
import { useI18n } from "@/composables/i18n";
import { useNumerals } from "@/composables/numerals";
export default {
  name: "CarCard",
  props: {
    cars: {
      type: Array,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    loadingCardsCount: {
      type: Number,
      default: 3,
    },
  },
  setup() {
    const { formatFloat } = useNumerals();
    const { t, currentLocale } = useI18n();
    return {
      t,
      formatFloat,
      currentLocale,
    };
  },
};
</script>
