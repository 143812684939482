<template>
  <div dir="ltr" class="md:pt-24 flex flex-row justify-center">
    <carousel-3d
      v-if="!loading && Object.keys(car).length"
      class="m-0 p-0 max-w-[110rem]"
      :height="500"
      :width="900"
      :count="car.images.length"
      :minSwipeDistance="50"
      :controls-visible="car.images.length > 1"
    >
      <slide
        v-if="!car.images.length"
        :key="0"
        :index="0"
        class="m-0 p-0 md:rounded-xl"
      >
        <img
          class="p-20 max-w-[110rem] bg-gray-200 h-full object-cover"
          src="@/assets/logo-gray.svg"
          alt="No Car Image"
        />
      </slide>
      <slide
        v-else
        v-for="(image, i) in car.images"
        class="md:rounded-xl shadow-xl cursor-pointer"
        :key="i"
        :index="i"
      >
        <img
          class="h-full object-cover"
          :src="image"
          :alt="'Car Image ' + (i + 1)"
        />
      </slide>
    </carousel-3d>
    <img
      v-else
      class="p-20 w-full h-auto lg:w-[900] lg:h-[500px] border shadow-md md:rounded-xl bg-gray-200 max-w-[110rem]"
      :class="{ 'animate-pulse': loading }"
      src="@/assets/logo-gray.svg"
      alt="No Car Image"
    />
  </div>
  <div class="pt-7 flex flex-col lg:flex-row lg:gap-20 justify-center">
    <div class="flex flex-col pb-8 mx-4 md:w-3/5 max-w-5xl">
      <car-details-bar :car="car" :loading="loading" />
      <hr />
      <div class="flex flex-col gap-2">
        <p v-if="!loading" class="text-lg text-start pt-4">
          {{ t("hosted_by") }}
        </p>
        <p
          v-else
          class="text-lg text-start bg-gray-200 animate-pulse h-6 w-48 rounded-lg mt-4"
        ></p>
        <div class="flex flex-row justify-between items-center">
          <div class="flex flex-row gap-4 items-center">
            <div v-if="!loading" class="flex flex-col items-center">
              <div class="relative">
                <img
                  class="h-24 w-24 rounded-full border border-ejaro"
                  :src="car.user.image || dummyUser"
                  alt="Host Profile Picture"
                />
              </div>
              <p
                class="absolute border mt-20 px-3 py-1 text-xs rounded-lg gap-1 justify-center bg-white border-ejaro text-ejaro"
              >
                {{ formatFloat(car.user.rating, "0.0") }}
                <img
                  class="w-3 inline-block align-middle"
                  src="@/assets/icons/star.svg"
                  alt=""
                />
              </p>
            </div>
            <div v-else class="flex flex-row items-center gap-4">
              <div
                class="h-24 w-24 rounded-full bg-gray-200 animate-pulse"
              ></div>
              <div class="flex flex-col gap-2">
                <p
                  class="text-start bg-gray-200 animate-pulse rounded-lg w-36 h-6"
                ></p>
                <span class="bg-gray-200 animate-pulse rounded-lg h-5 w-20">
                </span>
                <p
                  class="text-start bg-gray-200 w-52 h-6 animate-pulse rounded-lg"
                ></p>
              </div>
            </div>
            <div v-if="!loading" class="flex flex-col gap-2">
              <p class="text-start">
                {{ car.user.fname }} {{ car.user.lname[0] }}
              </p>
              <span
                class="bg-ejaro w-fit text-white rounded-lg py-2 px-3 font-bold text-sm"
              >
                {{ car.user.starTag }}
              </span>
              <p class="text-start">
                {{ t("rentals", { count: car.totalTrips }) }} |
                {{ t("joined_on", { date: car.user.joinedAt }) }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <br />
      <hr />
      <car-info-bar
        v-if="!loading"
        :title="t('vehicle_description')"
        :subtitle="car.description || t('no_description')"
        :leadingIcon="descriptionIcon"
        :actionTitle="
          car.description.length > 690
            ? !viewAll
              ? t('view_all')
              : t('view_less')
            : null
        "
        :actionInNewLine="true"
        :actionCallback="
          () => {
            viewAll = !viewAll;
          }
        "
      >
        <template #subtitle>
          <p
            :class="[
              'text-justify',
              viewAll ? 'h-max' : 'max-h-36 overflow-y-clip',
              !car.description && 'text-stone-400',
            ]"
          >
            {{
              car.description.length > 690 && !viewAll
                ? car.description.slice(0, 690).trimEnd() + "..."
                : car.description || t("no_description")
            }}
          </p>
        </template>
      </car-info-bar>
      <div v-else>
        <div class="flex flex-col gap-3 py-4">
          <div class="h-6 w-48 bg-gray-200 animate-pulse rounded-lg"></div>
          <div class="h-6 w-56 bg-gray-200 animate-pulse rounded-lg"></div>
        </div>
        <hr />
      </div>

      <div class="flex flex-row items-center justify-between">
        <p v-if="!loading" class="text-lg text-start py-4">
          {{ t("booking.rental.terms") }}
        </p>
        <p
          v-else
          class="bg-gray-200 animate-pulse w-36 rounded-md py-3 my-4"
        ></p>
      </div>
      <div
        v-if="!loading"
        class="grid grid-cols-2 lg:grid-cols-3 gap-7 py-5 text-center"
      >
        <div class="flex flex-col gap-2 items-center justify-center">
          <img
            class="h-10"
            src="@/assets/images/arrive_on_time.png"
            alt="Arrive on time"
          />
          <p>{{ t("arrive_on_time") }}</p>
        </div>
        <div class="flex flex-col gap-2 items-center justify-center">
          <img
            class="h-10"
            src="@/assets/images/bring_your_license.png"
            alt="Bring your license"
          />
          <p>{{ t("bring_your_license") }}</p>
        </div>
        <div class="flex flex-col gap-2 items-center justify-center">
          <img
            class="h-10"
            src="@/assets/images/keep_the_vehicle_clean.png"
            alt="Keep the vehicle clean"
          />
          <p>{{ t("keep_vehicle_clean") }}</p>
        </div>
        <div class="flex flex-col gap-2 items-center justify-center">
          <img
            class="h-10"
            src="@/assets/images/no_smoking.png"
            alt="No smoking"
          />
          <p>
            {{ t("smoking." + car.rentalTerms.smoking) }}
          </p>
        </div>
        <div class="flex flex-col gap-2 items-center justify-center">
          <img class="h-10" src="@/assets/images/km.png" alt="Included KMs" />
          <p>
            {{ t("km_included.per_day", { km: car.rentalTerms.km_included }) }}
          </p>
        </div>
        <div class="flex flex-col gap-2 items-center justify-center">
          <img
            class="h-10"
            src="@/assets/images/petrol.png"
            alt="Refuel with"
          />
          <p>
            {{
              t("refuel_with", {
                petrol_type: t(
                  "fuel_type." +
                    car.rentalTerms.fuel.split(" ").join("_").toLowerCase()
                ),
              })
            }}
          </p>
        </div>
      </div>
      <div v-else class="flex flex-col gap-2">
        <div class="flex flex-row justify-around items-center py-5">
          <div class="flex flex-col gap-2 items-center justify-center">
            <div class="h-10 w-10 rounded-lg bg-gray-200 animate-pulse"></div>
            <p class="h-4 w-16 bg-gray-200 animate-pulse rounded-md"></p>
          </div>
          <div class="flex flex-col gap-2 items-center justify-center">
            <div class="h-10 w-10 rounded-lg bg-gray-200 animate-pulse"></div>
            <p class="h-4 w-16 bg-gray-200 animate-pulse rounded-md"></p>
          </div>
          <div class="flex flex-col gap-2 items-center justify-center">
            <div class="h-10 w-10 rounded-lg bg-gray-200 animate-pulse"></div>
            <p class="h-4 w-16 bg-gray-200 animate-pulse rounded-md"></p>
          </div>
          <div class="flex flex-col gap-2 items-center justify-center">
            <div class="h-10 w-10 rounded-lg bg-gray-200 animate-pulse"></div>
            <p class="h-4 w-16 bg-gray-200 animate-pulse rounded-md"></p>
          </div>
          <div class="flex flex-col gap-2 items-center justify-center">
            <div class="h-10 w-10 rounded-lg bg-gray-200 animate-pulse"></div>
            <p class="h-4 w-16 bg-gray-200 animate-pulse rounded-md"></p>
          </div>
          <div class="flex flex-col gap-2 items-center justify-center">
            <div class="h-10 w-10 rounded-lg bg-gray-200 animate-pulse"></div>
            <p class="h-4 w-16 bg-gray-200 animate-pulse rounded-md"></p>
          </div>
        </div>
      </div>
      <hr />
      <div
        v-if="!loading"
        class="flex flex-row text-lg items-center gap-2 py-8"
      >
        <img src="@/assets/icons/star.svg" alt="" />
        <p>{{ formatFloat(car.avgRating, "0.0") }}</p>
        <p>|</p>
        <p>{{ t("reviews.with_count", { count: reviews.length }) }}</p>
      </div>
      <div
        v-else
        class="bg-gray-200 animate-pulse w-48 rounded-md py-3 my-8"
      ></div>
      <hr />
      <div v-if="!loading" class="flex flex-col gap-2 py-8">
        <review-progress-bar
          :name="t('car_review_type.listing_accuracy')"
          :value="car.rating.accuracy"
          textSize="text-base"
        />
        <review-progress-bar
          :name="t('car_review_type.vehicle_condition')"
          :value="car.rating.condition"
          textSize="text-base"
        />
        <review-progress-bar
          :name="t('car_review_type.money_value')"
          :value="car.rating.value"
          textSize="text-base"
        />
        <review-progress-bar
          :name="t('car_review_type.cleanliness')"
          :value="car.rating.clean"
          textSize="text-base"
        />
        <review-progress-bar
          :name="t('car_review_type.communication')"
          :value="car.rating.communication"
          textSize="text-base"
        />
      </div>
      <div v-else class="flex flex-col gap-2 py-8">
        <review-progress-bar :loading="true" />
        <review-progress-bar :loading="true" />
        <review-progress-bar :loading="true" />
        <review-progress-bar :loading="true" />
        <review-progress-bar :loading="true" />
      </div>
      <hr />
      <br />
      <review-card :review="{ userReviews: reviews }" :loading="loading" />
      <br class="hidden md:block" />
      <modal-dialog :isOpen="showModal" v-bind="modalData" />
    </div>
    <div class="flex flex-col justify-start items-center my-5 lg:my-0">
      <checkout-card
        :loading="loading"
        :car="car"
        :checkoutEstimation="checkoutEstimation"
      />
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { ref, watch } from "vue";
import { useRouter } from "vue-router";
import { useI18n } from "@/composables/i18n";
import { useNumerals } from "@/composables/numerals";
import { carDetails, carPriceEstimate } from "@/service/car";

import ReviewCard from "../components/ReviewCard.vue";
import CarInfoBar from "../components/CarInfoBar.vue";
import ModalDialog from "@/components/ModalDialog.vue";
import CheckoutCard from "@/components/CheckoutCard.vue";
import CarDetailsBar from "@/components/CarDetailsBar.vue";
import ReviewProgressBar from "@/components/ReviewProgressBar.vue";

import dummyUser from "@/assets/icons/user-anonymous.svg";
import descriptionIcon from "@/assets/icons/clipboard.svg";

export default {
  name: "Car",
  components: {
    ReviewCard,
    CarInfoBar,
    ModalDialog,
    CheckoutCard,
    CarDetailsBar,
    ReviewProgressBar,
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const router = useRouter();
    const { formatFloat } = useNumerals();

    const car = ref({});
    const reviews = ref([]);
    const nearbyCars = ref([]);
    const loading = ref(false);

    const showModal = ref(false);
    const modalData = ref({
      modalTitle: "Title",
      modalMessage: "Message",
      modalButtonText: "Button Text",
      closeModal: () => {
        showModal.value = false;
      },
    });
    const checkoutEstimation = ref({});

    const viewAll = ref(false);

    watch(
      () => [
        router.currentRoute.value.params,
        store.state.startDateTime,
        store.state.searchQuery.lat,
        store.state.searchQuery.lng,
      ],
      async (parameters) => {
        const params = parameters[0];
        if (params.car_id) {
          loading.value = true;

          let details = await carDetails(
            params.car_id,
            store.state.user,
            store.getters.formattedStartDate,
            store.state.searchQuery.lat,
            store.state.searchQuery.lng
          );

          if (details.code === 200) {
            car.value = details.result;
            reviews.value = details.reviews;
            nearbyCars.value = details.nearAbout;
          } else if (details.code === 404) {
            modalData.value = {
              modalTitle: "Car Not Found", // TODO: i18n
              modalMessage: "Oops! The car you are looking for is not found!", // TODO: i18n
              modalButtonText: "Okay", // TODO: i18n
              closeModal: () => {
                showModal.value = false;
                router.back();
              },
            };
            showModal.value = true;
          } else {
            router.replace({ name: "login" });
          }
        }
        loading.value = false;
      },
      { immediate: true }
    );

    watch(
      () => [store.state.startDateTime, store.state.endDateTime],
      async () => {
        loading.value = true;

        const response = await carPriceEstimate(
          router.currentRoute.value.params.car_id,
          store.state.startDateTime,
          store.state.endDateTime
        );

        if (response.code === 200) {
          checkoutEstimation.value = response.result;
        } else if (response.code === 442) {
          if (store.state.isSearchDropdownOpen) {
            store.commit("toggleSearchDropdown");
          }
          modalData.value = {
            modalTitle: "Car Not Available", // TODO: i18n
            modalMessage:
              "Oops! The car you are looking for is not available on the selected dates!", // TODO: i18n
            modalButtonText: "Okay", // TODO: i18n
            closeModal: () => {
              showModal.value = false;
              store.commit("toggleSearchDropdown");
            },
          };
          showModal.value = true;
          // router.replace({ name: "login" });
        }

        if (Object.keys(car.value).length > 0) {
          loading.value = false;
        }
      },
      { immediate: true }
    );

    return {
      t,
      car,
      viewAll,
      reviews,
      loading,
      showModal,
      modalData,
      dummyUser,
      nearbyCars,
      formatFloat,
      descriptionIcon,
      checkoutEstimation,
    };
  },
};
</script>
