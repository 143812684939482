import { createStore } from "vuex";
import { format, parseISO, addDays, addHours } from "date-fns";
import createPersistedState from "vuex-persistedstate";
import { getFormattedDateISOStr } from "@/common";
import { signIn, signOut, sessionDetails } from "@/service/auth";

const store = createStore({
  // State is a data store
  state: {
    user: null,
    searchQuery: {
      text: "",
      lat: null,
      lng: null,
    },
    startDateTime: getFormattedDateISOStr(addHours(new Date(), 2)),
    endDateTime: getFormattedDateISOStr(addDays(addHours(new Date(), 2), 3)),
    isSearchDropdownOpen: false,
  },
  // Getters are used to return a computed value from the store
  getters: {
    formattedStartDate(state) {
      return format(parseISO(state.startDateTime), "dd-MM-yyyy");
    },
    formattedEndDate(state) {
      return format(parseISO(state.endDateTime), "dd-MM-yyyy");
    },
    formattedSearchQueryDates(state) {
      const startDatetime = parseISO(state.startDateTime);
      const endDatetime = parseISO(state.endDateTime);

      return {
        fromDate: format(startDatetime, "yyyy-MM-dd"),
        fromTime: format(startDatetime, "HH:mm xxx"),
        tillDate: format(endDatetime, "yyyy-MM-dd"),
        tillTime: format(endDatetime, "HH:mm xxx"),
      };
    },
    numberOfDays(state) {
      const startDatetime = parseISO(state.startDateTime);
      const endDatetime = parseISO(state.endDateTime);

      return Math.ceil((endDatetime - startDatetime) / (1000 * 60 * 60 * 24));
    },
  },
  // Mutations are functions that effect the state and they must be sync.
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    toggleSearchDropdown(state) {
      state.isSearchDropdownOpen = !state.isSearchDropdownOpen;
    },
    updateSearchQuery(state, { text, lat, lng }) {
      state.searchQuery.text = text;
      state.searchQuery.lat = lat;
      state.searchQuery.lng = lng;
    },
    setStartDateTime(state, payload) {
      state.startDateTime = getFormattedDateISOStr(payload);
    },
    setEndDateTime(state, payload) {
      state.endDateTime = getFormattedDateISOStr(payload);
    },
    setUserLocation(state, payload) {
      state.searchQuery.lat = payload.lat;
      state.searchQuery.lng = payload.lng;
    },
    updateDateAfterNow(state) {
      const startDateTime = parseISO(state.startDateTime);

      if (startDateTime.getTime() < Date.now()) {
        state.startDateTime = getFormattedDateISOStr(addHours(new Date(), 2));
        state.endDateTime = getFormattedDateISOStr(
          addDays(addHours(new Date(), 2), 3)
        );
      }
    },
  },
  // Actions are functions that you call throughout your application that call mutations and they can be async.
  actions: {
    async login(context, { email, password, rememberMe }) {
      const response = await signIn(email, password);

      if (response.code === 200) {
        context.commit("setUser", response.result.id);

        if (rememberMe) {
          localStorage.setItem("token", response.result.sessionToken);
        } else {
          sessionStorage.setItem("token", response.result.sessionToken);
        }
      } else {
        return response.message;
      }
    },

    async logout(context) {
      await signOut();
      localStorage.removeItem("token");
      sessionStorage.removeItem("token");
      context.commit("setUser", null);
      window.location.reload();
    },

    async checkSession(context) {
      const response = await sessionDetails();
      if (response.code === 200) {
        context.commit("setUser", response.result.id);
      } else {
        localStorage.removeItem("token");
        sessionStorage.removeItem("token");
        context.commit("setUser", null);
      }
    },
  },
  plugins: [createPersistedState({ storage: window.sessionStorage })],
});

export default store;
