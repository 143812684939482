<template>
  <div
    class="grid grid-flow-row justify-items-center items-center gap-28 p-5 grid-cols-2 lg:grid-cols-3"
  >
    <img class="h-12" src="@/assets/icons/mastercard-2.svg" alt="Mastercard" />
    <img class="h-12" src="@/assets/icons/visa.svg" alt="Visa" />
    <img class="h-12" src="@/assets/icons/mada.svg" alt="Mada" />
    <img class="h-20" src="@/assets/icons/apple-pay-logo.svg" alt="Apple Pay" />
    <img
      class="h-12"
      src="@/assets/images/transport-g-authority.png"
      alt="Transport General Authority"
    />
    <img
      class="h-12"
      src="@/assets/icons/saudi-vision-2030.svg"
      alt="Vision 2030"
    />
  </div>
</template>

<script>
export default {
  name: "PaymentMethodsBar",
};
</script>
