<template>
  <div class="pt-5 md:pt-28 flex flex-col md:flex-row gap-10 justify-center">
    <div class="flex flex-col justify-start items-center">
      <profile-card :review="review" :user="user" :loading="loading" />
    </div>
    <div class="flex flex-col pb-8 mx-4 md:w-3/5 max-w-5xl">
      <p v-if="!loading" class="text-xl rtl:text-right">
        {{ t("host_vehicles", { host_name: user.fname }) }}
      </p>
      <span
        v-else
        class="h-5 w-1/4 rounded-lg bg-gray-200 animate-pulse"
      ></span>
      <br />
      <div class="grid grid-flow-row md:grid-cols-2 gap-x-4 gap-y-8">
        <template v-if="loading">
          <CarCard :loading="loading" :loadingCardsCount="2" />
        </template>
        <template v-else-if="cars.length">
          <CarCard :loading="loading" :cars="cars" />
        </template>
        <div
          v-else
          class="flex flex-col col-span-2 gap-3 items-center justify-center text-stone-400"
        >
          <img
            class="w-1/6"
            src="@/assets/icons/no-cars.svg"
            alt="No Host Cars"
          />
          <p>{{ t("no_host_vehicles") }}</p>
        </div>
      </div>
      <br />
      <p v-if="!loading" class="text-xl rtl:text-right">
        {{ t("host_fav_vehicles", { host_name: user.fname }) }}
      </p>
      <span
        v-else
        class="h-5 w-1/4 rounded-lg bg-gray-200 animate-pulse"
      ></span>
      <br />
      <div class="grid grid-flow-row md:grid-cols-2 gap-x-4 gap-y-8">
        <template v-if="loading">
          <CarCard :loading="loading" :loadingCardsCount="2" />
        </template>
        <template v-else-if="favCars.length">
          <CarCard :loading="loading" :cars="favCars" />
        </template>
        <div
          v-else
          class="flex flex-col col-span-2 gap-3 items-center justify-center text-stone-400"
        >
          <img
            class="w-1/6"
            src="@/assets/icons/no-favourite.svg"
            alt="No Favourite Cars"
          />
          <p>{{ t("no_host_fav_vehicles") }}</p>
        </div>
      </div>
      <br />
      <hr class="border-b border-stone-200 w-full my-4" />
      <div v-if="!loading">
        <div class="flex flex-row items-center gap-2 mx-3">
          <img src="@/assets/icons/star.svg" alt="" />
          <p>
            {{
              !isNaN(parseFloat(review.avgUserRating))
                ? parseFloat(review.avgUserRating).toFixed(1)
                : "4.5"
            }}
          </p>
          <p>|</p>
          <p>
            {{ t("reviews.with_count", { count: review.totalUserReview }) }}
          </p>
        </div>
        <hr class="border-b border-stone-200 w-full my-4" />
      </div>
      <div v-else>
        <div class="flex flex-row items-center gap-2 mx-3">
          <span class="h-4 w-14 rounded-lg bg-gray-200 animate-pulse"></span>
          <span class="h-4 w-14 rounded-lg bg-gray-200 animate-pulse"></span>
        </div>
        <hr class="border-b border-stone-200 w-full my-4" />
      </div>
      <review-card :review="review" :loading="loading" />
    </div>
  </div>
</template>

<script>
import { ref, watch } from "vue";
import { useRouter } from "vue-router";
import { userProfile } from "@/service/user";
import { useI18n } from "@/composables/i18n";
import CarCard from "@/components/CarCard.vue";
import ReviewCard from "@/components/ReviewCard.vue";
import ProfileCard from "@/components/ProfileCard.vue";
// import LoadingCarCard from "@/components/LoadingCarCard.vue";

export default {
  name: "Profile",
  components: {
    CarCard,
    ReviewCard,
    ProfileCard,
    // LoadingCarCard,
  },
  setup() {
    const { t } = useI18n();
    const router = useRouter();

    const cars = ref([]);
    const user = ref({});
    const review = ref({});
    const favCars = ref([]);
    const loading = ref(false);

    watch(
      () => router.currentRoute.value.params,
      async (params) => {
        if (params.user_id) {
          loading.value = true;
          let profile = await userProfile(params.user_id);

          if (profile.code === 200) {
            user.value = profile.user;
            cars.value = profile.userCar;
            review.value = profile.review;
            favCars.value = profile.favCar;
          } else {
            router.replace({ name: "login" });
          }

          loading.value = false;
        }
      },
      { immediate: true }
    );
    return {
      t,
      user,
      cars,
      review,
      favCars,
      loading,
    };
  },
};
</script>
