<template>
  <footer
    class="flex flex-row flex-wrap justify-center items-center text-center gap-10 xl:gap-28 p-3 bg-offwhite text-sm"
  >
    <p>{{ t("all_rights_reserved") }}&nbsp;{{ t("ejaro") }} © {{ year }}</p>
    <p>
      <a
        :href="
          currentLocale() === 'en'
            ? 'https://ejaro.com/pdf/Terms_and_Conditions_Agreement.pdf'
            : 'https://ejaro.com/pdf/Terms_&_conditions_AR.pdf'
        "
      >
        {{ t("terms_and_conditions") }} |
      </a>
      <a :href="`https://pages.ejaro.com/privacy_policy_${currentLocale()}`">{{
        t("privacy_policy")
      }}</a>
      |
      <a
        :href="
          currentLocale() === 'en'
            ? 'https://ejaro.com/pdf/Ejaro_Cancellation_Policy.pdf'
            : 'https://ejaro.com/pdf/cancellation_policy_AR.pdf'
        "
      >
        {{ t("cancellation_and_refund_policy") }}
      </a>
      |
      <a
        :href="
          currentLocale() === 'en'
            ? 'https://ejaro.com/pdf/Payment_Terms.pdf'
            : 'https://ejaro.com/pdf/Payments_policy_AR.pdf'
        "
      >
        {{ t("payment_policy") }}
      </a>
    </p>
    <div class="flex flex-row gap-x-3">
      <a href="https://www.facebook.com/ejaroapp/">
        <img class="h-10" src="@/assets/icons/facebook.svg" alt="Facebook" />
      </a>
      <a href="https://www.instagram.com/ejaro.app/">
        <img class="h-10" src="@/assets/icons/instagram.svg" alt="Instagram" />
      </a>
      <a href="https://twitter.com/EjaroApp">
        <img class="h-10" src="@/assets/icons/twitter.svg" alt="Twitter" />
      </a>
      <a href="https://www.youtube.com/channel/UCMjS_rF-ybOzUDSTRmm0W5g">
        <img class="h-10" src="@/assets/icons/youtube.svg" alt="YouTube" />
      </a>
    </div>
  </footer>
</template>

<script>
import { useI18n } from "@/composables/i18n";
export default {
  name: "FooterBar",
  setup() {
    const { t, currentLocale } = useI18n();
    const year = new Date().getFullYear();
    return { t, year, currentLocale };
  },
};
</script>
