import { format, parseISO } from "date-fns";
import { arSA, enUS } from "date-fns/locale";
import { useI18n } from "@/composables/i18n";

export const useDate = () => {
  const { currentLocale } = useI18n();

  function formatDate(
    date,
    formatString = "dd MMM - HH:mm a",
    locale = currentLocale()
  ) {
    return format(parseISO(date), formatString, {
      locale: locale === "ar" ? arSA : enUS,
    });
  }

  return {
    formatDate,
  };
};
