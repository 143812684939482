<template>
  <div
    v-if="!loading"
    class="flex flex-col items-center flex-shrink shadow-md border rounded-xl w-11/12 md:w-96 p-4 bg-white"
  >
    <div class="relative">
      <img
        class="h-24 w-24 rounded-full border border-ejaro"
        :src="user.image || dummyUser"
        alt="Profile Picture"
      />
    </div>
    <p
      class="absolute border mt-20 px-3 py-1 text-xs rounded-md gap-1 justify-center bg-white border-ejaro text-ejaro"
    >
      {{ parseFloat(user.rating).toFixed(1) }}
      <img
        class="w-3 inline-block align-middle"
        src="@/assets/icons/star.svg"
        alt="Rating Star"
      />
    </p>
    <span class="mt-4"></span>
    <p class="text-xl">{{ user.fname }} {{ user.lname }}</p>
    <p class="text-xs text-stone-400">
      {{ t("joined_on", { date: user.joinedAt }) }}
    </p>
    <hr class="border-b border-stone-200 w-full my-4" />
    <div
      class="flex flex-row justify-start items-center gap-2 text-start w-full"
    >
      <p>{{ t("rentals", { count: user.totalTrips }) }}</p>
      <p>|</p>
      <p>{{ t("reviews.with_count", { count: review.totalUserReview }) }}</p>
    </div>
    <hr class="border-b border-stone-200 w-full my-4" />
    <review-progress-bar
      :name="t('host_review_type.professionalism')"
      :value="review.avgProfessionalismRatings"
      :loading="loading"
    />
    <review-progress-bar
      :name="t('host_review_type.check_in_out')"
      :value="review.avgCheckRatings"
      :loading="loading"
    />
    <review-progress-bar
      :name="t('host_review_type.response_time')"
      :value="review.avgResponseTimeRatings"
      :loading="loading"
    />
    <review-progress-bar
      :name="t('host_review_type.communication')"
      :value="review.avgCommunicationRatings"
      :loading="loading"
    />
    <hr class="border-b border-stone-200 w-full my-4" />
    <p class="w-full text-start text-stone-400">{{ t("verified_info") }}</p>
    <br />
    <verified-info :name="t('identity')" :value="true" />
    <verified-info :name="t('driving_license')" :value="user.driveApproved" />
    <verified-info :name="t('email')" :value="user.emailVerified" />
    <verified-info :name="t('phone_number')" :value="true" />
  </div>
  <div
    v-else
    class="flex flex-col items-center flex-shrink shadow-md border rounded-xl w-11/12 md:w-96 p-4 bg-white"
  >
    <div class="h-24 w-24 rounded-full border bg-gray-200 animate-pulse"></div>
    <span class="mt-4"></span>
    <span class="h-5 w-28 bg-gray-200 animate-pulse rounded-lg"></span>
    <span class="mt-2 h-4 w-24 bg-gray-200 animate-pulse rounded-lg"></span>
    <hr class="border-b border-gray-200 w-full my-4" />
    <div class="flex flex-row justify-start items-center gap-2 w-full">
      <span class="h-4 w-16 bg-gray-200 animate-pulse rounded-lg"></span>
      <span class="h-4 w-16 bg-gray-200 animate-pulse rounded-lg"></span>
    </div>
    <hr class="border-b border-gray-200 w-full my-4" />
    <review-progress-bar :loading="true" />
    <review-progress-bar :loading="true" />
    <review-progress-bar :loading="true" />
    <review-progress-bar :loading="true" />
    <hr class="border-b border-gray-200 w-full my-4" />
    <verified-info :loading="true" />
    <verified-info :loading="true" />
    <verified-info :loading="true" />
    <verified-info :loading="true" />
  </div>
</template>

<script>
import { useI18n } from "@/composables/i18n";
import VerifiedInfo from "./VerifiedInfo.vue";
import dummyUser from "@/assets/icons/user-anonymous.svg";
import ReviewProgressBar from "./ReviewProgressBar.vue";

export default {
  components: { ReviewProgressBar, VerifiedInfo },
  name: "ProfileCard",
  props: {
    user: {
      type: Object,
      required: true,
    },
    review: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      required: false,
    },
  },
  setup(props) {
    const { t } = useI18n();

    return {
      t,
      dummyUser,
    };
  },
};
</script>
