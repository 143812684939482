import router from "../router/router";

export const getFormattedDateISOStr = (date) => {
  return new Date(date.getTime() - date.getTimezoneOffset() * 60000)
    .toISOString()
    .replace(/T/, " ")
    .replace(/\..+/, "");
};

export const redirectToDownload = (deep_link) => {
  const isMobile = /android.+mobile|ip(hone|[oa]d)/i.test(navigator.userAgent);

  if (deep_link) {
    window.location.href = deep_link;
  } else if (isMobile) {
    window.location.href = "https://ejr.app";
  } else {
    router.push({ name: "home", hash: "#download-app" });
  }
};
