import I18n from "../../packs/i18n/i18n";

export const useI18n = () => {
  function t(...args) {
    const [key, params] = args;
    return I18n.t("js." + key, params);
  }

  function currentLocale() {
    return I18n.currentLocale();
  }

  function setLocale(locale) {
    localStorage.setItem("lang", locale);
    document.documentElement.setAttribute("lang", locale);
    location.reload();
  }

  return {
    t,
    currentLocale,
    setLocale,
  };
};
