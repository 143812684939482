<template>
  <div
    class="grid grid-flow-row justify-items-center gap-14 md:grid-cols-2 xl:grid-cols-4 items-center md:gap-12 bg-offwhite p-5 rtl:text-right"
  >
    <div class="max-w-xs">
      <p>{{ t("benefits.explore_saudi_leading") }}</p>
      <p class="text-xl">{{ t("benefits.vehicle_sharing_marketplace") }}</p>
    </div>
    <div class="flex flex-row max-w-xs">
      <img
        class="w-12 mx-3"
        src="@/assets/icons/competitive-price.svg"
        alt="Competitive Prices"
      />
      <div>
        <p class="text-ejaro pb-2">
          {{ t("benefits.competitive_prices.title") }}
        </p>
        <p class="text-sm">
          {{ t("benefits.competitive_prices.description") }}
        </p>
      </div>
    </div>
    <div class="flex flex-row max-w-xs">
      <img
        class="w-14 mx-3"
        src="@/assets/icons/two-cars.svg"
        alt="Competitive Prices"
      />
      <div>
        <p class="text-ejaro pb-2">{{ t("benefits.more_options.title") }}</p>
        <p class="text-sm">
          {{ t("benefits.more_options.description") }}
        </p>
      </div>
    </div>
    <div class="flex flex-row max-w-xs">
      <img
        class="w-12 mx-3"
        src="@/assets/icons/quick-clock.svg"
        alt="Competitive Prices"
      />
      <div>
        <p class="text-ejaro pb-2">{{ t("benefits.quick_and_easy.title") }}</p>
        <p class="text-sm">{{ t("benefits.quick_and_easy.description") }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from "@/composables/i18n";
export default {
  name: "BenefitsBar",
  setup() {
    const { t } = useI18n();
    return { t };
  },
};
</script>
