<template>
  <div
    class="md:py-10 lg:py-28 flex flex-col lg:flex-row gap-10 justify-center"
  >
    <div class="flex flex-col justify-start items-center">
      <filter-card
        :filters="filters"
        :loading="filtersLoading"
        :showFilters="showFilters"
        :toggleFilters="toggleFilters"
      />
    </div>

    <div class="flex flex-col mx-4 lg:w-3/5 max-w-7xl">
      <div class="flex flex-row justify-between gap-3">
        <Menu as="div" class="relative">
          <MenuButton class="flex border border-stone-500 rounded-md">
            <div class="flex flex-row">
              <span class="py-2 px-3 hover:text-gray-500 focus:outline-none">
                {{ t("sort_by.text") }}
              </span>
            </div>
          </MenuButton>
          <transition
            enter-active-class="transition ease-out duration-100"
            enter-from-class="transform opacity-0 scale-95"
            enter-to-class="transform opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75"
            leave-from-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-95"
          >
            <MenuItems
              class="absolute ltr:left-0 rtl:right-0 z-10 mt-2 w-max origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
            >
              <MenuItem
                v-for="sortItem in sortingList"
                class="hover:bg-ejaro hover:text-white"
                :key="sortItem.label"
                @click="selectSort(sortItem.value)"
              >
                <div
                  :class="[
                    'cursor-pointer',
                    'py-2 text-sm text-gray-700 flex flex-row justify-start items-center px-4',
                    sortItem.value.val === $route.query.sort_value
                      ? 'bg-gray-100'
                      : '',
                  ]"
                >
                  {{
                    t(
                      "sort_by." +
                        sortItem.label
                          .toLowerCase()
                          .split(":")
                          .join("")
                          .split(" ")
                          .join("_")
                    )
                  }}
                </div>
              </MenuItem>
            </MenuItems>
          </transition>
        </Menu>
        <button
          class="lg:hidden flex border border-stone-500 rounded-md py-2 px-3"
          @click="toggleFilters"
        >
          {{ t("filters") }}
        </button>
      </div>
      <div class="flex flex-row items-center mt-3 gap-2">
        <h6 class="text-start text-sm">
          {{ t("review_and_follow") }}
        </h6>
        <img
          class="rtl:transform rtl:-scale-x-100"
          src="@/assets/icons/info.svg"
          alt="More Information Button"
        />
      </div>
      <br />
      <br />
      <h2 class="text-start text-xl">{{ t("vehicles_in", { place }) }}</h2>
      <br />
      <div class="grid grid-flow-row md:grid-cols-2 2xl:grid-cols-3 gap-5 pb-8">
        <car-card :loading="loading" :cars="cars" />
      </div>
      <div
        class="flex flex-col col-span-2 gap-3 items-center justify-center text-stone-400"
        v-if="!loading && !cars.length"
      >
        <img
          class="w-1/6"
          src="@/assets/icons/no-cars.svg"
          alt="No Cars Found"
        />
        <p>{{ t("no_cars_found") }}</p>
      </div>
      <br />
      <div class="flex items-center justify-center">
        <nav
          class="isolate inline-flex -space-x-px rounded-md shadow-sm"
          aria-label="Pagination"
        >
          <router-link
            :to="{
              query: {
                ...$route.query,
                page:
                  $route.query.page > 1
                    ? parseInt($route.query.page) - 1
                    : $route.query.page,
              },
            }"
            :class="[
              'relative inline-flex items-center rounded-l-md border border-gray-300 rtl:transform rtl:rotate-180 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20',
              parseInt($route.query.page) === 1 && 'cursor-not-allowed',
            ]"
          >
            <span class="sr-only">Previous</span>
            <ChevronLeftIcon class="h-5 w-5" aria-hidden="true" />
          </router-link>
          <!-- Current: "z-10 bg-indigo-50 border-indigo-500 text-indigo-600", Default: "bg-white border-gray-300 text-gray-500 hover:bg-gray-50" -->
          <router-link
            v-for="page in totalPages"
            :key="page"
            :to="{ query: { ...$route.query, page: page } }"
            replace
            aria-current="page"
            :class="[
              'relative inline-flex items-center border px-4 py-2 text-sm font-medium hover:bg-gray-50 focus:z-20',
              $route.query.page == page
                ? 'z-10 bg-red-600 bg-opacity-20 border-ejaro text-ejaro'
                : 'border-gray-300 bg-white text-gray-500',
            ]"
          >
            {{ page }}
          </router-link>
          <router-link
            :to="{
              query: {
                ...$route.query,
                page:
                  $route.query.page < totalPages
                    ? parseInt($route.query.page) + 1
                    : $route.query.page,
              },
            }"
            :class="[
              'relative inline-flex items-center rounded-r-md border border-gray-300 rtl:transform rtl:rotate-180 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20',
              parseInt($route.query.page) === totalPages &&
                'cursor-not-allowed',
            ]"
          >
            <span class="sr-only">Next</span>
            <ChevronRightIcon class="h-5 w-5" aria-hidden="true" />
          </router-link>
        </nav>
      </div>
      <br />
    </div>
  </div>
</template>

<script>
import { searchCars } from "@/service/car";
import CarCard from "@/components/CarCard.vue";
import FilterCard from "@/components/FilterCard.vue";
import { ref, watch } from "@vue/runtime-core";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { carFilters } from "../service/car";
import { useI18n } from "@/composables/i18n";
import { Menu, MenuItem, MenuItems, MenuButton } from "@headlessui/vue";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/vue/20/solid";

export default {
  components: {
    CarCard,
    FilterCard,
    Menu,
    MenuItem,
    MenuItems,
    MenuButton,
    ChevronLeftIcon,
    ChevronRightIcon,
  },
  setup() {
    const cars = ref([]);
    const filters = ref({});
    const { t } = useI18n();
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const totalCount = ref(0);
    const totalPages = ref(1);
    const loading = ref(false);
    const filtersLoading = ref(true);
    const showFilters = ref(false);
    const sortingList = ref([]);

    const place = ref(store.state.searchQuery.text);

    const toggleFilters = () => {
      showFilters.value = !showFilters.value;
    };

    const selectSort = (value) => {
      router.replace({
        query: {
          ...route.query,
          sort_attribute: value.attribute,
          sort_value: value.val,
        },
      });
    };

    watch(
      () => route.query,
      async () => {
        loading.value = true;
        place.value = store.state.searchQuery.text;

        if (filtersLoading.value) {
          const filtersResponse = await carFilters();

          if (filtersResponse.code === 200) {
            // filters.value["colors"] = filtersResponse.colors;
            filters.value["transmissions"] = filtersResponse.transmissions;
            filters.value["fuelTypes"] = filtersResponse.fuelTypes;
            // filters.value["vechileTypes"] = filtersResponse.vechileTypes;
            filters.value["seats"] = filtersResponse.seats;
            filters.value["years"] = filtersResponse.years;
            filters.value["makes"] = filtersResponse.makes;
            filters.value["features"] = filtersResponse.features;

            sortingList.value = filtersResponse.sorting;
          }
          filtersLoading.value = false;
        }

        store.commit("updateDateAfterNow");

        let query = {
          ...route.query,
          sort_attribute: route.query.sort_attribute || "rel",
          sort_val: route.query.sort_value || "",
          filters_unitPriceRange_min:
            route.query["filters_unitPriceRange_min"] || 0,
          filters_unitPriceRange_max:
            route.query["filters_unitPriceRange_max"] || 0,
          filters_yearRange_min: route.query["filters_yearRange_min"] || 0,
          filters_yearRange_max: route.query["filters_yearRange_max"] || 0,
          filters_seats: route.query["filters_seats"] || 0,
          filters_make: route.query["filters_make"] || 0,
          filters_model: route.query["filters_model"] || 0,
          filters_deliveryStatus: route.query["filters_deliveryStatus"] || 0,
          filters_milePerDay: route.query["filters_milePerDay"] || 0,
          filters_transmission:
            route.query["filters_transmission"] instanceof Array
              ? route.query["filters_transmission"]
              : (route.query["filters_transmission"] && [
                  route.query["filters_transmission"],
                ]) ||
                [],
          // filters_vehicleTypes:
          //   typeof route.query["filters_vehicleTypes"] === "object"
          //     ? route.query["filters_vehicleTypes"]
          //     : (route.query["filters_vehicleTypes"] && [
          //         route.query["filters_vehicleTypes"],
          //       ]) ||
          //       [],
          // filters_colors:
          //   typeof route.query["filters_colors"] === "object"
          //     ? route.query["filters_colors"]
          //     : (route.query["filters_colors"] && [
          //         route.query["filters_colors"],
          //       ]) ||
          //       [],
          filters_fuelTypes:
            route.query["filters_fuelTypes"] instanceof Array
              ? route.query["filters_fuelTypes"]
              : (route.query["filters_fuelTypes"] && [
                  route.query["filters_fuelTypes"],
                ]) ||
                [],
        };

        const response = await searchCars(query);

        if (response.code === 200) {
          cars.value = response.result;
          totalCount.value = response.totalCar;
          totalPages.value = response.totalPages;
        }

        loading.value = false;
      },
      { immediate: true }
    );
    return {
      t,
      cars,
      place,
      filters,
      loading,
      totalCount,
      selectSort,
      totalPages,
      showFilters,
      sortingList,
      toggleFilters,
      filtersLoading,
    };
  },
};
</script>
