<template>
  <div :class="!scroll && 'overflow-hidden h-full fixed'">
    <AppBar
      v-if="$route.name !== 'login'"
      @sideNav="(value) => (scroll = !value)"
    />
    <div class="flex flex-col min-h-screen justify-between">
      <router-view />
      <FooterBar v-if="$route.name !== 'login'" />
    </div>
  </div>
</template>

<script>
import AppBar from "@/components/AppBar.vue";
import FooterBar from "@/components/FooterBar.vue";

export default {
  components: { AppBar, FooterBar },
  data() {
    return {
      scroll: true,
    };
  },
  created() {
    this.$store.dispatch("checkSession");
  },
};
</script>
