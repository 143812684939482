import axios from "axios";
import { getHeaders } from ".";

export const nearbyCars = async (userId, lat = 0, lng = 0) => {
  try {
    let res = await axios.post(
      "/api/v1/mob/homepage/listing",
      {
        latitude: lat,
        longitude: lng,
        user_id: userId,
      },
      { headers: getHeaders() }
    );

    return res.data;
  } catch (error) {
    console.log("Error", error);
    return error;
  }
};

export const carDetails = async (carId, userId, fromDate, lat = 0, lng = 0) => {
  try {
    let res = await axios.post(
      "/api/v1/mob/car/details",
      {
        car_id: carId,
        fromDate,
        user_id: userId,
        latitude: lat,
        longitude: lng,
      },
      { headers: getHeaders() }
    );

    return res.data;
  } catch (error) {
    console.log("Error", error);
    return error;
  }
};

export const carPriceEstimate = async (carId, tripStart, tripEnd) => {
  try {
    let res = await axios.post(
      "/api/v1/common/booking/price/estimate",
      {
        carId,
        tripStart,
        tripEnd,
      },
      {
        headers: getHeaders(),
      }
    );

    return res.data;
  } catch (error) {
    console.log("Error", error);
    return error;
  }
};

/*
  the payload should be like this:
  {
    fromDate: yyyy-MM-dd, e.g. 2022-01-01
    fromTime: HH:mm xxx, e.g. 12:00 +03:00,
    tillDate: yyyy-MM-dd, e.g. 2022-01-05
    tillTime: HH:mm xxx, e.g. 12:00 +03:00,
    page: 1,
    per_page: 10,
    latitude: 21.485811,
    longitude: 39.19250479999999,
  }
*/
export const searchCars = async (payload) => {

  let filters = {};
  let sortData = {};
  let otherData = {};

  Object.keys(payload).forEach((key) => {
    const keys = key.split("_");

    if (keys[0] === "filters") {
      if (keys.length > 2) {
        Object.assign(filters, {
          [keys[1]]: {
            ...filters[keys[1]],
            [keys[2]]: payload[key],
          },
        });
      } else {
        filters[keys[1]] = payload[key];
      }
    } else if (keys[0] === "sort") {
      sortData[keys[1]] = payload[key];
    } else {
      otherData[key] = payload[key];
    }
  });

  payload = {
    filters: { ...filters },
    sort: { ...sortData },
    ...otherData,
  };

  try {
    let res = await axios.post("/api/v1/mob/cars/filter", payload, {
      headers: getHeaders(),
    });

    return res.data;
  } catch (error) {
    console.log("Error", error);
    return error;
  }
};

export const carFilters = async () => {
  try {
    let res = await axios.get("/api/v1/common/filter/preloads", {
      headers: getHeaders(),
    });

    return res.data;
  } catch (error) {
    console.log("Error", error);
    return error;
  }
};

export const getModels = async (makeId) => {
  try {
    let res = await axios.post(
      "/api/v1/common/models/preloads",
      { makeId },
      {
        headers: getHeaders(),
      }
    );

    return res.data;
  } catch (error) {
    console.log("Error", error);
    return error;
  }
};
