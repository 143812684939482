<template>
  <form method="POST" @submit="search">
    <div
      :class="[
        'flex flex-row items-center shadow-lg h-10 max-w-3xl z-10 mx-4 md:mx-4 lg:px-0 xl:col-span-6 bg-white rounded-xl',
        isAbsolute
          ? 'absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-1/2'
          : 'relative',
      ]"
    >
      <img class="w-5 mx-2" src="@/assets/icons/location.svg" alt="location" />
      <input
        :required="true"
        name="location"
        type="text"
        class="flex-1 w-full h-full rounded-xl placeholder:text-stone-800 border-0 focus:outline-none rtl:ml-4 ltr:mr-4"
        :placeholder="t('city_search')"
        :value="searchQuery.text"
        ref="ac"
      />
      <img
        class="w-5 ml-2"
        src="@/assets/icons/date-time.svg"
        alt="start date & time"
      />
      <input
        readonly
        name="trip_dates"
        type="text"
        class="flex-1 w-full h-full rounded-xl cursor-pointer placeholder:text-stone-800 border-0 focus:outline-none ml-2 ltr:mr-4"
        :placeholder="t('date_search')"
        @click="toggleSearchDropdown"
        :value="formattedStartDate() + ' - ' + formattedEndDate()"
      />
      <button
        type="submit"
        :class="[
          searchBtnColor !== 'ejaro' && 'bg-ejaro',
          'flex ltr:rounded-r-xl rtl:rounded-l-xl items-center h-full px-3 cursor-pointer',
        ]"
      >
        <img
          :class="[
            'w-5',
            searchBtnColor === 'ejaro' ? 'fill-ejaro' : 'fill-white',
          ]"
          src="@/assets/icons/magnify.svg"
          alt="Search Icon"
          onload="SVGInject(this)"
        />
      </button>
      <SearchDropDown :rows="1" :cols="!isAbsolute ? 1 : 2" />
    </div>
  </form>
</template>

<script>
import { useI18n } from "@/composables/i18n";
import { mapState, mapMutations, mapGetters, useStore } from "vuex";
import { SVGInject } from "@iconfu/svg-inject";
import SearchDropDown from "@/components/SearchDropDown.vue";
import { onMounted, ref } from "@vue/runtime-core";
// import { searchCars } from "@/service/car";
import { Loader } from "@googlemaps/js-api-loader";
import { useRouter, useRoute } from "vue-router";
// import { format, parseISO } from "date-fns";

export default {
  name: "SearchBar",
  components: { SearchDropDown },

  props: {
    searchBtnColor: {
      type: String,
      default: "white",
    },
    isAbsolute: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const { t, currentLocale } = useI18n();
    const ac = ref(null);
    const store = useStore();
    const autoComplete = ref(null);
    const router = useRouter();
    const route = useRoute();

    const search = (e) => {
      e.preventDefault();

      store.commit("updateDateAfterNow");

      const payload = {
        page: 1,
        per_page: 10,
        ...route.query,
        latitude: store.state.searchQuery.lat,
        longitude: store.state.searchQuery.lng,
        ...store.getters.formattedSearchQueryDates,
      };

      if (route.name === "cars") {
        router.replace({ name: "cars", query: { ...payload } });
      } else {
        router.push({ name: "cars", query: { ...payload } });
      }
    };

    onMounted(() => {
      const loader = new Loader({
        apiKey: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
        version: "weekly",
        libraries: ["places"],
        language: currentLocale(),
        region: "SA",
      });

      loader.load().then(() => {
        if (ac.value) {
          autoComplete.value = new google.maps.places.Autocomplete(ac.value);
          autoComplete.value.addListener("place_changed", () => {
            const place = autoComplete.value.getPlace();
            if (place.geometry) {
              store.commit("updateSearchQuery", {
                text: place.name, //place.formatted_address,
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng(),
              });
            }
          });
        }
      });
    });

    return { t, ac, search, SVGInject };
  },
  computed: {
    ...mapState([
      "searchQuery",
      "startDateTime",
      "endDateTime",
      "isSearchDropdownOpen",
    ]),
  },
  methods: {
    ...mapMutations(["toggleSearchDropdown"]),
    ...mapGetters(["formattedEndDate", "formattedStartDate"]),
  },
};
</script>
