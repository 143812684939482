<template>
  <div class="flex flex-col gap-y-20 lg:flex-row">
    <div class="relative flex flex-col col-span-1 h-[50vw] lg:h-screen">
      <img
        class="object-cover h-full lg:max-w-3xl"
        src="@/assets/images/background-3.png"
        alt="Login Background"
        style="filter: brightness(0.5)"
      />
      <div
        class="absolute flex flex-col justify-between px-5 pt-3 md:px-20 md:pt-20 pb-32 items-center h-full w-full"
      >
        <div class="w-4/5">
          <router-link :to="{ name: 'home' }">
            <img class="w-2/6" src="@/assets/ejaro.svg" alt="Ejaro Logo" />
          </router-link>
        </div>
        <p class="md:text-2xl text-center w-3/4 pt-5 md:pt-0 text-white">
          {{ t("share_and_earn") }}
        </p>
        <br />
        <p class="md:block md:text-2xl text-white w-full text-center">
          {{ t("trustworthy_community") }}
        </p>
      </div>
    </div>
    <div class="flex flex-col items-center justify-center w-full col-span-2">
      <div class="w-4/5 lg:w-1/2 max-w-lg">
        <form @submit="signIn" method="post" class="flex flex-col w-full gap-5">
          <div>
            <h1 class="text-2xl font-bold w-full rtl:text-right">
              {{ t("welcome_back") }}!
            </h1>
            <h2 class="w-full rtl:text-right">{{ t("login_to_account") }}</h2>
          </div>
          <span
            v-if="errors.login.message"
            class="bg-red-200 text-ejaro text-center p-5 rounded-md"
          >
            {{ errors.login.message }}
          </span>
          <div class="flex flex-col w-full">
            <input
              type="email"
              name="email"
              id="email"
              required
              v-model="email"
              :placeholder="t('email')"
              :class="[
                'w-full border rounded-md p-3 focus:outline-none',
                !errors.email.valid ? 'border-red-500' : 'border-gray-300',
              ]"
              @input="validateEmail"
            />
            <span
              v-if="!errors.email.valid"
              class="text-xs text-ejaro pt-2 rtl:text-right"
              >{{ errors.email.message }}</span
            >
          </div>
          <div class="flex flex-col w-full">
            <input
              type="password"
              name="password"
              id="password"
              autocomplete="new-password"
              required
              v-model="password"
              :placeholder="t('password')"
              class="w-full border rounded-md p-3 focus:outline-none border-gray-300"
            />
          </div>
          <div class="flex flex-row-reverse justify-between items-center">
            <a href="" class="text-xs underline">
              {{ t("forgot_password") }}
            </a>
            <div class="text-sm flex flex-row items-center gap-x-2">
              <input
                class="w-4 h-4"
                type="checkbox"
                name="rememberMe"
                id="rememberMe"
                v-model="rememberMe"
              />
              <label class="mb-0" for="rememberMe">{{
                t("login.remember_me")
              }}</label>
            </div>
          </div>
          <div class="flex flex-col w-full">
            <button
              type="submit"
              :disabled="loading"
              class="w-full bg-ejaro text-white rounded-lg p-3 disabled:bg-gray-300 disabled:cursor-not-allowed"
            >
              <div class="flex flex-row justify-center" v-if="loading">
                <span
                  class="rounded-full w-8 h-8 border-4 border-t-white border-b-white border-r-white border-l-transparent animate-spin"
                ></span>
              </div>
              <span v-else>
                {{ t("sign_in") }}
              </span>
            </button>
          </div>
          <div class="flex flex-col w-full">
            <button
              type="button"
              :disabled="loading"
              class="w-full bg-transparent border border-gray-300 rounded-lg p-3 disabled:text-gray-300 disabled:cursor-not-allowed"
            >
              {{ t("no_account") }} &nbsp;
              <span class="font-bold">{{ t("register_now") }}</span>
            </button>
          </div>
        </form>
      </div>
      <br />
      <br />
    </div>
  </div>
</template>

<script>
import { useI18n } from "@/composables/i18n";
import { mapActions } from "vuex";
export default {
  name: "Login",
  data() {
    return {
      email: "",
      password: "",
      loading: false,
      rememberMe: false,
      errors: {
        email: { valid: true, message: this.t("login.errors.invalid_email") },
        login: { message: "" },
      },
    };
  },
  methods: {
    ...useI18n(),
    ...mapActions(["login"]),
    validEmail: function (email) {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    async signIn(e) {
      e.preventDefault();

      if (this.errors.email.valid) {
        this.loading = true;

        const message = await this.login({
          email: this.email,
          password: this.password,
          rememberMe: this.rememberMe,
        });

        this.loading = false;
        if (message) {
          this.errors.login.message = message;
        } else {
          this.$router.push({ name: "home" });
        }
      }
    },
    validateEmail() {
      if (this.email) {
        this.errors.email.valid = this.validEmail(this.email);
      }
    },
  },
};
</script>
