import Home from "@/Pages/Home.vue";
import Login from "@/Pages/Login.vue";
import Profile from "@/Pages/Profile.vue";
// import Booking from "@/Pages/Booking.vue";
import Car from "@/Pages/Car.vue";
import CarList from "@/Pages/CarList.vue";
import { sessionDetails } from "@/service/auth";
import { createRouter, createWebHistory } from "vue-router";
import store from "@/store/index.js";
import { userLocation } from "@/service/user";

const routes = [
  { path: "/", name: "home", component: Home, meta: { requiresLocaton: true } },
  { path: "/profile/:user_id", name: "profile", component: Profile },
  // {
  //   path: "/bookings/:type",
  //   name: "bookings",
  //   component: Booking,
  //   children: [
  //     { path: "requests", name: "requests", component: BookingRequests },
  //     { path: "current", name: "current", component: Booking },
  //     { path: "history", name: "history", component: Booking },
  //   ],
  // },
  {
    path: "/login",
    name: "login",
    component: Login,
    beforeEnter: async (to, from, next) => {
      const session = await sessionDetails();
      const isAuthenticated = session.code !== 345;
      if (isAuthenticated) next("/");
      else next();
    },
  },
  {
    path: "/cars",
    name: "cars",
    component: CarList,
    meta: { requiresLocaton: true },
  },
  {
    path: "/cars/:car_id",
    name: "car",
    component: Car,
    meta: { requiresLocaton: true },
  },
  { path: "/:pathMatch(.*)*", redirect: "/" },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) return { el: to.hash, behavior: "smooth" };
    if (savedPosition) return savedPosition;
    return { top: 0, behavior: "smooth" };
  },
});

router.beforeEach(async (to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresLocaton)) {
    if (
      EJARO_CONSTANTS.location.latitude &&
      EJARO_CONSTANTS.location.longitude
    ) {
      store.commit("setUserLocation", {
        lat: EJARO_CONSTANTS.location.latitude,
        lng: EJARO_CONSTANTS.location.longitude,
      });
    } else {
      const location = window.navigator && window.navigator.geolocation;
      if (location) {
        location.getCurrentPosition((position) => {
          store.commit("setUserLocation", {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        });
      }
    }
    next();
  } else next();
});

export default router;
