<template>
  <div
    v-if="!loading"
    class="flex flex-row items-center justify-between w-full pb-2 text-sm"
  >
    <p>{{ name }}</p>
    <img
      v-if="value"
      class="w-5"
      src="@/assets/icons/verified.svg"
      alt="Verified"
    />
    <img
      v-else
      class="w-5"
      src="@/assets/icons/not-verified.svg"
      alt="Not Verified"
    />
  </div>
  <div
    v-else
    class="flex flex-row items-center justify-between w-full pb-2 text-sm"
  >
    <span class="w-20 h-4 animate-pulse bg-gray-200 rounded-lg"></span>
    <span class="w-5 h-5 animate-pulse bg-gray-200 rounded-full"></span>
  </div>
</template>

<script>
export default {
  props: ["name", "value", "loading"],
};
</script>
