import axios from "axios";
import { getHeaders } from ".";

export const userProfile = async (user_id) => {
  try {
    let res = await axios.post(
      "/api/v1/web/user/profile",
      { user_id },
      { headers: getHeaders() }
    );
    return res.data;
  } catch (error) {
    return { code: error.response.status, message: error.message };
  }
};

export const userBookings = async (keyword = "", page = 1, per_page = 15) => {
  try {
    let res = await axios.post(
      "/api/v1/common/booking/list",
      { keyword, page, per_page },
      { headers: getHeaders() }
    );
    return res.data;
  } catch (error) {
    return { code: error.response.status, message: error.message };
  }
};

export const userLocation = async () => {
  try {
    let res = await fetch("https://ipapi.co/json/", {
      method: "GET",
    });
    return res.json();
  } catch (error) {
    return { code: error.response.status, message: error.message };
  }
};
