<template>
  <div
    v-if="!loading"
    class="flex flex-col flex-shrink gap-4 shadow-md border rounded-xl w-11/12 md:w-96 p-4 bg-white"
  >
    <h1 class="text-2xl text-start font-medium">
      {{
        t("price_per_day", {
          price: checkoutEstimation.pricePerDay,
        })
      }}
    </h1>
    <hr />
    <h5 class="text-sm text-start text-gray-400">
      {{ t("booking.rental.summary") }}
    </h5>
    <hr />
    <div class="flex flex-row justify-between">
      <p>
        {{ formatDate(startDateTime) }}
      </p>
      <img
        class="w-5 transform rtl:rotate-180"
        src="@/assets/icons/white-chevron-right.svg"
        alt="Chevron Right"
      />
      <p>{{ formatDate(endDateTime) }}</p>
    </div>
    <hr />
    <div class="flex flex-row justify-between">
      <p>
        {{
          t("booking.rental.days", {
            days: checkoutEstimation.totalDays,
          })
        }}
      </p>
      <p>
        {{
          t("price_in_sar", {
            price: formatFloat(checkoutEstimation.base_fare),
          })
        }}
      </p>
    </div>
    <div class="flex flex-row justify-between">
      <p>{{ t("service_fees") }}</p>
      <p>
        {{
          t("price_in_sar", {
            price: formatFloat(checkoutEstimation.serviceFee),
          })
        }}
      </p>
    </div>
    <div class="flex flex-row justify-between">
      <p>{{ t("vat", { value: checkoutEstimation.vatPercent }) }}</p>
      <p>
        {{
          t("price_in_sar", {
            price: formatFloat(checkoutEstimation.vatAmount),
          })
        }}
      </p>
    </div>
    <div class="flex flex-row justify-between">
      <p>{{ t("discount") }}</p>
      <p>
        -{{
          t("price_in_sar", {
            price: formatFloat(checkoutEstimation.discount),
          })
        }}
      </p>
    </div>
    <div class="flex flex-row font-bold justify-between">
      <p>{{ t("booking.rental.total") }}</p>
      <p>
        {{
          t("price_in_sar", {
            price: formatFloat(checkoutEstimation.finalPrice),
          })
        }}
      </p>
    </div>
    <!-- <div class="flex flex-row font-bold justify-between">
      <p>{{ t("promo_code") }}</p>
      <div class="flex flex-row items-center gap-2">
        <p class="">{{ t("apply_code") }}</p>
        <img class="h-5" src="@/assets/icons/promo-code.svg" alt="Promo Code" />
      </div>
    </div> -->
    <!-- <template v-if="checkoutEstimation.pricePerDay">
      <hr />
      <div class="text-sm text-gray-400 text-start">
        <p class="font-bold">{{ t("payment_method") }}</p>
        <p>{{ t("payment_method_message") }}</p>
      </div>
      <hr />
      <div class="flex flex-row items-center justify-between">
        <div class="flex flex-row gap-5">
          <img class="h-4" src="@/assets/icons/visa.svg" alt="Visa" />
          <p>**** 7893</p>
        </div>
        <p class="text-ejaro">{{ t("change") }}</p>
      </div>
    </template> -->
    <hr />
    <button
      @click="redirectToDownload(car.deep_link)"
      class="w-full bg-ejaro text-white rounded-lg py-3 font-bold"
    >
      {{ t("book_now") }}
    </button>
  </div>
  <div
    v-else
    class="flex flex-col flex-shrink gap-4 shadow-md border rounded-xl w-11/12 md:w-96 p-4 bg-white"
  >
    <h1 class="text-start h-6 w-44 bg-gray-200 animate-pulse rounded-lg"></h1>
    <hr />
    <h5 class="text-start h-5 w-36 rounded-lg bg-gray-200 animate-pulse"></h5>
    <hr />
    <div class="flex flex-row justify-between">
      <p class="h-5 w-36 bg-gray-200 rounded-lg animate-pulse"></p>
      <div class="h-5 w-5 bg-gray-200 animate-pulse rounded-full"></div>
      <p class="h-5 w-36 bg-gray-200 rounded-lg animate-pulse"></p>
    </div>
    <hr />
    <div class="flex flex-row justify-between">
      <p class="h-5 w-32 bg-gray-200 rounded-lg animate-pulse"></p>
      <p class="h-5 w-32 bg-gray-200 rounded-lg animate-pulse"></p>
    </div>
    <div class="flex flex-row justify-between">
      <p class="h-5 w-32 bg-gray-200 rounded-lg animate-pulse"></p>
      <p class="h-5 w-32 bg-gray-200 rounded-lg animate-pulse"></p>
    </div>
    <div class="flex flex-row justify-between">
      <p class="h-5 w-32 bg-gray-200 rounded-lg animate-pulse"></p>
      <p class="h-5 w-32 bg-gray-200 rounded-lg animate-pulse"></p>
    </div>
    <div class="flex flex-row justify-between">
      <p class="h-5 w-32 bg-gray-200 rounded-lg animate-pulse"></p>
      <p class="h-5 w-32 bg-gray-200 rounded-lg animate-pulse"></p>
    </div>
    <div class="flex flex-row font-bold justify-between">
      <p class="h-5 w-32 bg-gray-200 rounded-lg animate-pulse"></p>
      <p class="h-5 w-32 bg-gray-200 rounded-lg animate-pulse"></p>
    </div>
    <div class="flex flex-row font-bold justify-between">
      <p class="h-5 w-32 bg-gray-200 rounded-lg animate-pulse"></p>
      <p class="h-5 w-32 bg-gray-200 rounded-lg animate-pulse"></p>
    </div>
    <hr />
    <div class="text-sm flex flex-col gap-2 text-gray-400">
      <p class="h-5 w-32 bg-gray-200 rounded-lg animate-pulse"></p>
      <p class="h-5 w-full mt-1 bg-gray-200 rounded-lg animate-pulse"></p>
      <p class="h-5 w-28 bg-gray-200 rounded-lg animate-pulse"></p>
    </div>
    <hr />
    <div class="flex flex-row items-center justify-between">
      <p class="h-5 w-32 bg-gray-200 rounded-lg animate-pulse"></p>
      <p class="h-5 w-32 bg-gray-200 rounded-lg animate-pulse"></p>
    </div>
    <hr />
    <button
      disabled
      class="w-full bg-gray-200 animate-pulse rounded-lg h-14 py-3 font-bold"
    ></button>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { useI18n } from "@/composables/i18n";
import { useDate } from "@/composables/date";
import { redirectToDownload } from "@/common";
import { useNumerals } from "@/composables/numerals";
import ReviewProgressBar from "./ReviewProgressBar.vue";

export default {
  components: { ReviewProgressBar },
  name: "ProfileCard",
  props: {
    loading: {
      type: Boolean,
      required: false,
    },
    car: {
      type: Object,
      required: false,
    },
    checkoutEstimation: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState(["startDateTime", "endDateTime"]),
  },
  setup() {
    const { t } = useI18n();
    const { formatDate } = useDate();
    const { formatFloat } = useNumerals();

    return {
      t,
      formatDate,
      formatFloat,
      redirectToDownload,
    };
  },
};
</script>
