<template>
  <div
    class="grid text-center justify-items-center items-center gap-28 bg-offwhite py-4 md:grid-flow-row md:text-left md:grid-cols-2 lg:grid-cols-4"
  >
    <p class="text-lg text-gray-400">{{ t("featured_in") }}</p>
    <img class="md:w-auto" src="@/assets/icons/magnitt.svg" alt="Magnitt" />
    <img
      class="md:w-auto"
      src="@/assets/icons/startup_scene.png"
      alt="Startup Scene"
    />
    <img
      class="md:w-auto"
      src="@/assets/icons/waya.svg"
      alt="Competitive Prices"
    />
  </div>
</template>

<script>
import { useI18n } from "@/composables/i18n";
export default {
  name: "FeaturedBar",
  setup() {
    const { t } = useI18n();
    return { t };
  },
};
</script>
