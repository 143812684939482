<template>
  <div class="flex flex-col gap-2 pt-4">
    <slot name="title" :title="title">
      <p class="text-lg text-start">{{ title }}</p>
    </slot>
    <div class="flex flex-row justify-between items-start">
      <div class="flex flex-row gap-2 items-center">
        <slot name="icon" :icon="leadingIcon">
          <img v-if="leadingIcon" class="w-5" :src="leadingIcon" :alt="title" />
        </slot>
        <slot name="subtitle" :subtitle="subtitle">
          <p class="text-start">{{ subtitle }}</p>
        </slot>
      </div>
      <p
        v-if="actionTitle && !actionInNewLine"
        @click="actionCallback"
        class="text-ejaro text-end cursor-pointer"
      >
        {{ actionTitle }}
      </p>
    </div>
    <div class="flex flex-row justify-end">
      <p
        v-if="actionTitle && actionInNewLine"
        @click="actionCallback"
        class="text-ejaro text-end cursor-pointer"
      >
        {{ actionTitle }}
      </p>
    </div>
  </div>
  <br />
  <hr />
</template>

<script>
export default {
  name: "CarInfoBar",
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: false,
    },
    actionTitle: {
      type: String,
      required: false,
    },
    actionInNewLine: {
      type: Boolean,
      required: false,
    },
    actionCallback: {
      type: Function,
      required: false,
    },
    leadingIcon: {
      type: String,
      required: false,
    },
  },
};
</script>
