import axios from "axios";
import { getHeaders } from ".";

export const signIn = async (email, password) => {
  try {
    let res = await axios.post("/api/v1/common/signin", {
      email: email,
      password: password,
    });

    return res.data;
  } catch (error) {
    console.log("Error", error);
    return error;
  }
};

export const signOut = async () => {
  try {
    let res = await axios.get("/api/v1/common/signout", {
      headers: getHeaders(),
    });
    return res.date;
  } catch (error) {
    console.log("Error", error);
    return error;
  }
};

export const sessionDetails = async () => {
  try {
    let res = await axios.get("/api/v1/common/session/details", {
      headers: getHeaders(),
    });
    return res.data;
  } catch (error) {
    console.log("Error", error);
    return error;
  }
};
