<template>
  <div
    id="download-app"
    class="flex flex-col-reverse lg:flex lg:flex-row justify-center items-center gap-x-28"
  >
    <div class="text-center lg:text-left rtl:lg:text-right">
      <p class="text-2xl max-w-md mx-6 lg:mx-0">
        {{ t("slogan") }}
      </p>
      <br />
      <p class="text-3xl pb-3">{{ t("download_now") }}</p>
      <div class="flex flex-row justify-center xl:justify-start gap-x-4">
        <a href="https://apps.apple.com/in/app/ejaro/id1482484869">
          <img
            class="h-12"
            src="@/assets/icons/apple_store_app.svg"
            alt="Download on App Store"
          />
        </a>
        <a href="https://play.google.com/store/apps/details?id=com.ejaro">
          <img
            class="h-12"
            src="@/assets/icons/google_play_app.svg"
            alt="Download on Google Play Store"
          />
        </a>
      </div>
    </div>
    <img
      class="lg:w-1/3"
      src="@/assets/images/mobile-app-preview.jpg"
      alt="Ejaro App Preview"
    />
  </div>
</template>

<script>
import { useI18n } from "@/composables/i18n";
export default {
  name: "DownloadAppBar",
  setup() {
    const { t } = useI18n();
    return { t };
  },
};
</script>

<style></style>
