<template>
  <!-- When the mobile menu is open, add `overflow-hidden` to the `body` element to prevent double scrollbars -->
  <Popover as="template" v-slot="{ open }">
    <header
      id="header"
      :class="[
        open ? 'inset-0 z-40' : '',
        'w-full py-3 sticky lg:fixed lg:overflow-y-visible z-50 bg-ejaro',
        isTransparent ? 'lg:bg-transparent' : 'bg-ejaro',
      ]"
    >
      <div class="mx-auto px-4 sm:px-6 lg:px-8">
        <div
          class="relative flex flex-row-reverse lg:flex-row flex-grow justify-between"
        >
          <div class="flex md:inset-y-0 md:left-0 lg:static xl:col-span-2">
            <div class="flex flex-shrink-0 items-center">
              <router-link to="/">
                <img
                  class="block h-8 w-auto"
                  src="@/assets/logo.svg"
                  alt="Ejaro Logo"
                />
              </router-link>
            </div>
          </div>
          <SearchBar
            class="hidden lg:flex"
            v-if="isShowSearchBar"
            searchBtnColor="ejaro"
          />
          <div
            class="flex items-center lg:absolute lg:inset-y-0 md:right-0 lg:hidden"
          >
            <!-- Mobile menu button -->
            <PopoverButton
              class="-mx-2 inline-flex items-center justify-center rounded-md p-2 text-white focus:bg-red-800 outline-none"
              @click="$emit('sideNav', true)"
            >
              <span class="sr-only">Open menu</span>
              <Bars3Icon class="block h-6 w-6" aria-hidden="true" />
            </PopoverButton>
          </div>

          <div
            class="hidden lg:flex lg:items-center lg:justify-end xl:col-span-4"
          >
            <button
              id="header-btn"
              :class="[
                'inline-flex items-center rounded-md px-3 py-2 text-sm shadow-sm hover:no-underline',
                isTransparent ? 'bg-ejaro text-white' : 'bg-white text-ejaro',
              ]"
              @click="user.id ? redirectToDownload() : $router.push('/login')"
            >
              {{ t("share_your_vehicle") }}
            </button>
            <!-- language dropdown -->
            <Menu as="div" class="relative mx-2 flex-shrink-0">
              <div>
                <MenuButton class="flex rounded-full">
                  <div class="flex flex-row mx-1 rtl:flex-row-reverse">
                    <a
                      href="#"
                      class="flex-shrink-0 rounded-full p-1 text-gray-400 hover:text-gray-500 focus:outline-none"
                    >
                      <span class="sr-only">Change Language</span>
                      <img
                        class="w-6"
                        src="@/assets/icons/globe.svg"
                        alt="start date & time"
                      />
                    </a>
                    <img
                      class="w-5"
                      src="@/assets/icons/chevron-down.svg"
                      alt="chevron-down"
                    />
                  </div>
                </MenuButton>
              </div>
              <transition
                enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95"
              >
                <MenuItems
                  class="absolute ltr:right-0 rtl:left-0 z-10 mt-2 w-40 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                >
                  <MenuItem
                    v-for="locale in locales"
                    :key="locale.code"
                    @click="setLocale(locale.code)"
                  >
                    <div
                      :class="[
                        currentLocale() === locale.code
                          ? 'bg-gray-200'
                          : 'cursor-pointer',
                        'py-2 text-sm text-gray-700 flex flex-row justify-start items-center px-4',
                      ]"
                    >
                      {{ locale.name }}
                    </div>
                  </MenuItem>
                </MenuItems>
              </transition>
            </Menu>
            <!-- <a
              href="#"
              class="flex-shrink-0 rounded-full p-1 text-white hover:text-gray-500 outline-none focus:ring-0"
            >
              <span class="sr-only">View notifications</span>
              <Bars3Icon class="h-6 w-6" aria-hidden="true" />
            </a> -->

            <!-- Profile dropdown -->
            <Menu as="div" class="relative flex-shrink-0">
              <div>
                <MenuButton
                  class="flex rounded-full bg-white focus:outline-none"
                >
                  <span class="sr-only">Open user menu</span>
                  <img
                    class="h-12 w-12 rounded-full border border-white"
                    :src="user.image || dummyUser"
                    alt=""
                  />
                </MenuButton>
              </div>
              <transition
                enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95"
              >
                <MenuItems
                  class="absolute ltr:right-0 rtl:left-0 z-10 mt-2 w-72 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                >
                  <MenuItem
                    v-for="item in userNavigation1"
                    :key="item.name"
                    v-slot="{ active }"
                  >
                    <router-link :to="item.href">
                      <div
                        :class="[
                          'flex flex-row rtl:flex-row-reverse items-center justify-between px-4',
                          active ? 'bg-gray-100' : '',
                        ]"
                      >
                        <div
                          class="py-2 text-sm text-gray-700 flex flex-row flex-grow rtl:flex-row-reverse justify-start items-center gap-x-2"
                        >
                          <img class="h-4" :src="item.icon" alt="My-cars" />
                          {{ t(item.name) }}
                        </div>
                        <span
                          class="flex flex-row rtl:flex-row-reverse items-center justify-center align-middle gap-x-2"
                        >
                          <p class="text-ejaro text-sm">{{ item.count }}</p>
                          <img
                            class="w-2"
                            src="@/assets/icons/red-chevron-right.svg"
                            alt="Chevron Right"
                          />
                        </span>
                      </div>
                    </router-link>
                  </MenuItem>
                  <!-- <hr class="my-2" /> -->
                  <MenuItem
                    v-for="item in userNavigation2"
                    :key="item.name"
                    v-slot="{ active }"
                  >
                    <router-link :to="item.href">
                      <div
                        :class="[
                          'flex flex-row rtl:flex-row-reverse items-center justify-between px-4',
                          active ? 'bg-gray-100' : '',
                        ]"
                      >
                        <div
                          class="py-2 text-sm text-gray-700 flex flex-row flex-grow rtl:flex-row-reverse justify-start items-center gap-x-2"
                        >
                          <img class="h-4" :src="item.icon" alt="My-cars" />
                          {{ t(item.name) }}
                        </div>
                      </div>
                    </router-link>
                  </MenuItem>
                  <hr class="my-2" />
                  <MenuItem
                    v-for="item in userNavigation3"
                    :key="item.name"
                    v-slot="{ active }"
                  >
                    <router-link :to="item.href">
                      <div
                        :class="[
                          'flex flex-row rtl:flex-row-reverse items-center justify-between px-4',
                          active ? 'bg-gray-100' : '',
                        ]"
                      >
                        <div
                          class="py-2 text-sm text-gray-700 flex flex-row flex-grow rtl:flex-row-reverse justify-start items-center gap-x-2"
                        >
                          <img class="h-4" :src="item.icon" alt="My-cars" />
                          {{ t(item.name) }}
                        </div>
                      </div>
                    </router-link>
                  </MenuItem>
                  <MenuItem
                    class="cursor-pointer"
                    v-slot="{ active }"
                    @click="
                      () =>
                        user.id ? logout() : $router.push({ name: 'login' })
                    "
                  >
                    <div
                      :class="[
                        'flex flex-row rtl:flex-row-reverse items-center justify-between px-4',
                        active ? 'bg-gray-100 underline' : '',
                      ]"
                    >
                      <div
                        class="py-2 text-sm text-gray-700 flex flex-row flex-grow rtl:flex-row-reverse justify-start items-center gap-x-2"
                      >
                        <img
                          class="h-4"
                          src="@/assets/icons/power.svg"
                          alt="My-cars"
                        />
                        {{ user.id ? t("sign_out") : t("sign_in") }}
                      </div>
                    </div>
                  </MenuItem>
                </MenuItems>
              </transition>
            </Menu>
          </div>
        </div>
      </div>
      <transition
        enter-active-class="transition duration-200 ease-out"
        enter-from-class="-translate-x-full rtl:translate-x-full w-0"
        enter-to-class="translate-x-0 w-100"
        leave-active-class="transition duration-150 ease-in"
        leave-from-class="translate-x-0 w-100"
        leave-to-class="-translate-x-full rtl:translate-x-full w-0"
      >
        <PopoverPanel
          class="fixed inset-0 bg-gray-900 bg-opacity-30 w-full h-full shadow-md"
        >
          <div
            class="flex flex-col items-stretch justify-between pt-10 py-5 bg-white w-3/4 md:w-1/2 h-full"
          >
            <div class="flex flex-col items-stretch">
              <PopoverButton
                @click="$emit('sideNav', false)"
                class="flex justify-end w-full mb-4 px-4"
              >
                <XMarkIcon class="block h-6 w-6" aria-hidden="true" />
              </PopoverButton>
              <div class="flex flex-col items-center">
                <img
                  class="h-24 w-24 rounded-full border border-white ring-1 ring-ejaro"
                  :src="user.image || dummyUser"
                  alt=""
                />
                <p class="mt-2">
                  {{ (user.fname && user.fname + " " + user.lname) || "Guest" }}
                </p>
                <p class="text-sm text-ejaro">
                  {{ formatFloat(user.rating, "0.0") || "4.5" }}
                </p>
              </div>
              <br />
              <hr />
              <div
                v-for="item in mobileNavigation"
                :key="item.name"
                class="focus:bg-gray-100 w-full"
              >
                <router-link to="#">
                  <div
                    class="py-2 text-sm text-gray-700 flex flex-row justify-start items-center gap-x-4 px-4"
                  >
                    <div class="w-4 flex items-center justify-center">
                      <img class="h-4" :src="item.icon" :alt="item.name" />
                    </div>
                    <p>{{ t(item.name) }}</p>
                  </div>
                </router-link>
                <hr />
              </div>
              <div
                class="flex flex-row items-center justify-between focus:bg-gray-100 w-full"
                @click="setLocale(currentLocale() === 'en' ? 'ar' : 'en')"
              >
                <div
                  class="py-2 text-sm text-gray-700 flex flex-row justify-start items-center gap-x-4 px-4"
                >
                  <div class="w-4 flex items-center justify-center">
                    <img
                      class="h-4"
                      src="@/assets/icons/globe-gray.svg"
                      alt="Language"
                    />
                  </div>
                  <p>{{ t("language") }}</p>
                </div>
                <div class="py-2 px-4 text-sm text-gray-700">
                  <p>
                    {{ t("next_lang") }}
                  </p>
                </div>
              </div>
            </div>
            <div
              class="text-sm text-gray-700 flex flex-row justify-start items-center gap-x-4 px-4"
              @click="
                () => (user.id ? logout() : $router.push({ name: 'login' }))
              "
            >
              <div class="w-4">
                <img
                  class="h-4"
                  src="@/assets/icons/power.svg"
                  alt="Sign In/ Sign Out"
                />
              </div>
              <p>{{ user.id ? t("sign_out") : t("sign_in") }}</p>
            </div>
          </div>
        </PopoverPanel>
      </transition>
    </header>
  </Popover>
  <SearchBar
    class="py-5 lg:hidden bg-ejaro"
    v-if="isShowSearchBar"
    searchBtnColor="ejaro"
    :isAbsolute="false"
  />
</template>

<script>
import {
  Menu,
  Popover,
  MenuItem,
  MenuItems,
  MenuButton,
  PopoverPanel,
  PopoverButton,
} from "@headlessui/vue";
import { ref, watch } from "vue";
import { mapActions, useStore } from "vuex";
import { useI18n } from "@/composables/i18n";
import { userProfile } from "@/service/user";
import { redirectToDownload } from "@/common";
import { useNumerals } from "@/composables/numerals";
import { Bars3Icon, BellIcon, XMarkIcon } from "@heroicons/vue/24/outline";

import help from "@/assets/icons/help.svg";
import myCars from "@/assets/icons/my-cars.svg";
import SearchBar from "@/components/SearchBar.vue";
import settings from "@/assets/icons/settings.svg";
import myWallet from "@/assets/icons/my-wallet.svg";
import myFavourite from "@/assets/icons/favourite.svg";
import myBookings from "@/assets/icons/my-bookings.svg";
import myMessages from "@/assets/icons/my-messages.svg";
import dummyUser from "@/assets/icons/user-anonymous.svg";
import inviteAndEarn from "@/assets/icons/invite-and-earn.svg";

export default {
  components: {
    Menu,
    Popover,
    BellIcon,
    MenuItem,
    MenuItems,
    Bars3Icon,
    SearchBar,
    XMarkIcon,
    MenuButton,
    PopoverPanel,
    PopoverButton,
  },
  data() {
    return {
      isTransparent: true,
      isShowSearchBar: false,
    };
  },
  emits: ["sideNav"],
  watch: {
    $route(to, from) {
      if (to.name === "home") {
        this.isTransparent = true;
        this.isShowSearchBar = false;
      } else {
        this.isTransparent = false;
        this.isShowSearchBar = true;
      }
    },
  },
  mounted() {
    document.addEventListener("scroll", () => {
      if (this.$route.name === "home" && window.screen.availWidth > 768) {
        if (window.scrollY > window.screen.availHeight * 0.25) {
          this.isTransparent = false;
          this.isShowSearchBar = true;
        } else {
          this.isTransparent = true;
          this.isShowSearchBar = false;
        }
      }
    });
  },
  setup() {
    const store = useStore();
    const user = ref({});
    const user_cars = ref([]);
    const { t, currentLocale, setLocale } = useI18n();
    const { formatFloat } = useNumerals();

    const userNavigation1 = ref([
      {
        name: "my_cars",
        icon: myCars,
        count: user_cars.value.length,
        href: "/profile/",
      },
      // { name: "bookings", icon: myBookings, count: 0, href: "#" },
      // { name: "messages", icon: myMessages, count: 0, href: "#" },
    ]);

    const userNavigation2 = ref([
      // { icon: myWallet, name: "wallet", href: "#" },
      // { icon: myFavourite, name: "favourites", href: "#" },
      // { icon: inviteAndEarn, name: "invite_and_earn", href: "#" },
      // { icon: settings, name: "settings", href: "#" },
    ]);

    const userNavigation3 = ref([
      // { icon: help, name: "help", href: "#" }
    ]);

    const mobileNavigation = ref([
      ...userNavigation2.value,
      ...userNavigation3.value,
    ]);

    watch(
      () => store.state.user,
      async (newUser) => {
        userNavigation1.value[0].href = "/profile/" + newUser;
        const response = await userProfile(newUser);
        if (response.user) {
          user.value = response.user;
          user_cars.value = response.userCar;
          userNavigation1.value[0].count = user_cars.value.length;
        }
      },
      { immediate: true }
    );

    const locales = [
      { name: "العربية", code: "ar" },
      { name: "English", code: "en" },
    ];

    return {
      t,
      user,
      locales,
      dummyUser,
      setLocale,
      formatFloat,
      currentLocale,
      userNavigation1,
      userNavigation2,
      userNavigation3,
      mobileNavigation,
      redirectToDownload,
    };
  },
  methods: mapActions(["logout"]),
};
</script>
