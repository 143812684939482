<template>
  <div>
    <p class="text-center text-2xl mt-5">
      {{ t("popular_destinations") }} <br />
      <span class="text-lg text-gray-500">
        {{ t("choose_from_popular_cities") }}
      </span>
    </p>
    <br />
    <br />
    <div class="flex flex-row flex-wrap justify-center gap-12">
      <template v-if="cities.length > 0">
        <router-link
          @click="
            () =>
              $store.commit('updateSearchQuery', {
                text: city.title,
                lat: city.latitude,
                lng: city.longitude,
              })
          "
          :to="{
            name: 'cars',
            query: {
              page: 1,
              per_page: 10,
              latitude: city.latitude,
              longitude: city.longitude,
              ...$store.getters.formattedSearchQueryDates,
            },
          }"
          v-for="city in cities"
          :key="city.id"
          class="text-center"
        >
          <img
            class="w-36 h-36 mb-2 rounded-full bg-cover"
            :src="city.image"
            :alt="city.title"
          />
          <p>{{ city.title }}</p>
        </router-link>
      </template>
      <template v-else>
        <div
          v-for="i in 5"
          :key="i"
          class="text-center justify-items-center w-36 h-36 mb-2 rounded-full bg-gray-200 animate-pulse"
        >
          <img
            src="@/assets/icons/location-2.svg"
            alt="city.title"
            class="object-contain p-8 fill-blue-500"
          />
          <span
            class="flex text-center h-4 items-center justify-center w-full mt-3"
          >
            <div
              class="animate-pulse h-full w-3/4 rounded-lg bg-gray-200"
            ></div>
          </span>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { useI18n } from "@/composables/i18n";
export default {
  name: "CitiesBar",
  props: {
    cities: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const { t } = useI18n();
    return {
      t,
    };
  },
};
</script>
